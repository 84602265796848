import type {
  Integration} from '@pandler/shared/data-types';
import {
  integrations,
  isKnownIntegration,
} from '@pandler/shared/data-types';
import { LoadingIcon, success, ToggleNode } from '@pandler/shared/react-ui';
import { useDisableIntegration } from '../../hooks';

interface IntegrationNodeProps {
  integration: Integration;
  active: boolean;
  status?: string;
  connect?: (id: string) => Promise<void>;
  isLoading?: boolean;
  connectionId?: string;
}

export function IntegrationNode({
  integration,
  active,
  status,
  connect,
  isLoading,
  connectionId,
}: IntegrationNodeProps) {
  const { name, logo, id } = integration;
  const { mutate } = useDisableIntegration();

  const $integration = integration.name.toLowerCase();
  const details = isKnownIntegration($integration)
    ? integrations[$integration]
    : undefined;

  return (
    <ToggleNode
      name={name}
      on={active}
      icon={
        details?.logo || logo ? (
          <img
            className="h-8 w-8 rounded-full"
            src={details?.logo ? details.logo[0] : logo}
            alt=""
          />
        ) : null
      }
      description={details?.description}
      viewLabel={
        status === 'PendingAuth' ? (
          <span>
            {isLoading ? (
              <LoadingIcon
                className="w-5 h-5 text-indigo-600"
                strokeWidth={3}
              />
            ) : (
              'Authorize'
            )}
          </span>
        ) : (
          'Details'
        )
      }
      onView={() => {
        if (status === 'PendingAuth' && connect) {
          connect(id);
        }
      }}
      onDelete={async () => {
        if (!active && connect) {
          await connect(id);
        } else if (active && connectionId) {
          await mutate(connectionId);
          success({
            message: `${name} has been successfully disabled`,
            title: 'Integrations',
          });
        }
      }}
      confirmText={(on) => (
        <>
          Do you want to {on ? 'disconnect' : 'connect'}{' '}
          <span className="font-semibold">{name}</span>?
        </>
      )}
      bottomLeft={
        <>
          {status && status !== 'Linked' && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 ">
              {status.replace(/([A-Z])/g, ' $1')}
            </span>
          )}
          {status && status === 'Linked' && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-700">
              Active
            </span>
          )}
        </>
      }
    />
  );
}
