import { type ReactNode } from 'react';

export interface OptionCardProps {
  className?: string;
  icon: ReactNode;
  title: string;
  description?: string;
  tag?: string;
  onClick?: () => void;
}

export function OptionCard({
  className,
  icon,
  title,
  description,
  tag,
  onClick,
}: OptionCardProps) {
  return (
    <div
      className={`${className} rounded-2xl pb-20 shadow-sm relative hover:bg-slate-50/40 transition-all duration-200`}
    >
      {tag && (
        <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-1.5 px-4 bg-indigo-500 rounded-full text-xs font-semibold mx-auto flex tracking-wide text-white">
          {tag}
        </span>
      )}
      <div className="pt-8 px-6 pb-6 mb-2">
        <div className="h-12 flex justify-center">{icon}</div>
      </div>

      <div className="pb-4 px-8 text-center">
        <h3 className="font-semibold mb-3 text-gray-900">{title}</h3>
        {description && <p className="text-sm text-gray-500">{description}</p>}
      </div>

      <div className="absolute bottom-0 inset-x-0  px-4 py-4 sm:px-6">
        <div className="text-sm">
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={onClick}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
}
