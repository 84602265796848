import { LogoIcon } from '@pandler/shared/react-ui';
import { BuilderPane } from '../builder-pane';
import type { WorkflowProps } from '../workflow';
import { Workflow } from '../workflow';

export interface EditorProps {
  onNodeClick?: WorkflowProps['onNodeClick'];
  nodes: WorkflowProps['nodes'];
  edges: WorkflowProps['edges'];
}

export function Editor({ onNodeClick, nodes, edges }: EditorProps) {
  return (
    <div className="flex min-h-full">
      <Workflow
        className="grow bg-transparent rounded-xl overflow-hidden"
        onNodeClick={onNodeClick}
        nodes={nodes}
        edges={edges}
      />
      <BuilderPane
        className="ml-8 w-[30%] min-h-max bg-white shadow-inner rounded-xl overflow-hidden"
        icon={<LogoIcon className="text-indigo-600 h-8" />}
      />
    </div>
  );
}
