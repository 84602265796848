import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { type FC, Fragment, type ReactNode, useEffect, useState } from 'react';
import type {
  Toast} from 'react-hot-toast';
import {
  toast as rhToast,
  Toaster as RHToaster,
  type ToasterProps,
} from 'react-hot-toast';
import { LoadingIcon } from '../loading-icon';

type ToastType = 'success' | 'info' | 'warning' | 'error' | 'loading';

export type ToastProps = Partial<
  Pick<
    Toast,
    | 'id'
    | 'icon'
    | 'duration'
    | 'ariaProps'
    | 'className'
    | 'style'
    | 'position'
    | 'iconTheme'
  >
> & {
  type: ToastType;
  title?: string | ReactNode;
  message: string | ReactNode;
};

const Toaster: FC = (props: ToasterProps) => (
  <RHToaster
    {...props}
    toastOptions={{
      className: 'shadow-md rounded-xl bg-white text-black-500',
    }}
  />
);

type PlatformToastProps = {
  show: boolean;
  initialVisible?: boolean;
  dismissible?: boolean;
} & Pick<ToastProps, 'type' | 'title' | 'message'>;

function PlatformToast({
  show,
  title,
  message,
  type,
  initialVisible = false,
  dismissible = true,
}: PlatformToastProps) {
  const [visible, setVisible] = useState(initialVisible);
  useEffect(() => {
    setVisible(show);
  }, [show]);

  return (
    <Transition
      show={visible}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-md w-full bg-white shadow-md rounded-xl pointer-events-auto overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              {type === 'success' && (
                <CheckCircleIcon
                  className="h-10 w-10 text-green-400"
                  aria-hidden="true"
                />
              )}
              {type === 'error' && (
                <XCircleIcon
                  className="h-10 w-10 text-red-500"
                  aria-hidden="true"
                />
              )}
              {type === 'warning' && (
                <ExclamationCircleIcon
                  className="h-10 w-10 text-amber-400"
                  aria-hidden="true"
                />
              )}
              {type === 'info' && (
                <InformationCircleIcon
                  className="h-10 w-10 text-blue-400"
                  aria-hidden="true"
                />
              )}
              {type === 'loading' && (
                <LoadingIcon
                  className="text-indigo-600 w-6 h-6 mt-1"
                  strokeWidth={4}
                />
              )}
            </div>
            <div className="ml-3.5 w-0 flex-1 pt-0.5">
              {title && (
                <p className="text-xl font-bold text-black-700">{title}</p>
              )}
              {message && <p className="mt-1 text-gray-500">{message}</p>}
            </div>
            {dismissible && (
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
}

function toast(args: ToastProps) {
  const { title, message, type, ...rest } = args;

  rhToast.custom((t) => {
    return (
      <PlatformToast
        show={t.visible}
        title={title}
        message={message}
        type={type}
      />
    );
  }, rest);
}

export function success(props: Omit<ToastProps, 'type'>) {
  return toast({ type: 'success', title: 'Success', ...props });
}

export function error(props: Omit<ToastProps, 'type'>) {
  return toast({ type: 'error', title: 'An error occurred', ...props });
}

export function info(props: Omit<ToastProps, 'type'>) {
  return toast({ type: 'info', title: 'Info', ...props });
}

export function warning(props: Omit<ToastProps, 'type'>) {
  return toast({ type: 'warning', title: 'Warning', ...props });
}

export { Toaster, ToasterProps, toast, PlatformToast };
