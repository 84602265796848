import { useMemo, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  DefaultInputClassName,
  Input,
  LoadingIcon,
  OptionsButton,
} from '@pandler/shared/react-ui';

import './email-form.css';
import { HtmlWysiwyg } from '@pandler/shared/html-wysiwyg';

const noop = () => {
  // nothing
};

export interface FormValues {
  to: string;
  subject: string;
  message: string;
}

export interface EmailFormProps {
  to?: string;
  subject?: string;
  message?: string;
  onSubmit?: (values: FormValues) => void;
  onQueue?: (values: FormValues) => void;
  isLoading?: boolean;
  submitLabel?: string;
  hideQueue?: boolean;
  additionalOptions?: ReactNode | ReactNode[];
  className?: string;
  apiKey?: string;
}

export function EmailForm({
  to = '',
  subject = '',
  message = '',
  isLoading = false,
  onSubmit = noop,
  onQueue = noop,
  submitLabel = 'Send',
  hideQueue = false,
  additionalOptions,
  className = '',
  apiKey,
}: EmailFormProps) {
  const editorRef = useRef<any | null>(null);
  const [values, setValues] = useState<FormValues>({ to, subject, message });

  const setValue = (property: keyof FormValues, value: string) =>
    setValues((values) => ({ ...values, [property]: value }));

  const handleOnSubmit = () => {
    const message = editorRef.current ? editorRef.current.getContent() : '';
    return onSubmit({ ...values, message });
  };

  const isValid = Object.values(values).every(Boolean);
  const items = useMemo(
    () =>
      hideQueue || isLoading
        ? []
        : [
            {
              label: 'Queue',
              onClick: () => onQueue(values),
            },
          ],
    [hideQueue, isLoading, onQueue, values]
  );

  return (
    <div className={twMerge('p-2 space-y-2 flex flex-col', className)}>
      <div>
        <Input
          type="email"
          name="to"
          placeholder="Recipients"
          value={values.to}
          onChange={(e) => setValue('to', e.target.value)}
        />
      </div>
      <div>
        <Input
          type="text"
          name="subject"
          placeholder="Subject"
          value={values.subject}
          onChange={(e) => setValue('subject', e.target.value)}
        />
      </div>
      <div>
        <div
          className={twMerge(
            DefaultInputClassName,
            'py-0 px-0 border-none overflow-hidden'
          )}
        >
          <HtmlWysiwyg
            apiKey={apiKey}
            ref={editorRef}
            initialValue={values.message}
            height="550px"
            loader={
              <LoadingIcon
                className="w-8 h-8 text-indigo-600 my-4 mx-auto"
                strokeWidth={2.5}
              />
            }
          />
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <OptionsButton
          label={
            isLoading ? (
              <LoadingIcon className="w-5 h-5 mx-auto" strokeWidth={4} />
            ) : (
              submitLabel
            )
          }
          disabled={!isValid || isLoading}
          onClick={handleOnSubmit}
          items={items}
        />
        {additionalOptions ?? null}
      </div>
    </div>
  );
}
