import {
  ModalHeader,
  TINYMCE_API_KEY,
  useOrganization,
  useSendEmail,
} from '@pandler/cloud/shared';
import type { Customer, Invoice } from '@pandler/shared/data-types';
import {
  CheckboxGroup,
  error as showError,
  success,
} from '@pandler/shared/react-ui';
import { EmailForm, type FormValues } from '@pandler/shared/inbox-ui';
import { compile } from '@pandler/shared/templates';
import { useCallback, useState } from 'react';
import type { EmailMessage } from '../../types';

interface SendEmailTemplateProps {
  customer: Customer;
  invoice: Invoice;
  email: EmailMessage;
  onSuccess: () => void;
}

export function SendEmailTemplate({
  customer,
  invoice,
  email,
  onSuccess,
}: SendEmailTemplateProps) {
  const [attachment, setAttachment] = useState(true);
  const organization = useOrganization();
  const { mutate: sendEmail, isLoading } = useSendEmail();
  const process = useCallback(
    (message: string) =>
      compile(message, {
        customer,
        invoice,
        company: organization.organization,
      }),
    [customer, invoice, organization]
  );

  const handleSend =
    (type: 'queue' | 'send') =>
    ({ subject, to, message }: FormValues) =>
      sendEmail(
        {
          type,
          email: {
            subject,
            to: { email: to },
            content: { type: 'text/html', value: message },
            attachments: [
              ...(attachment
                ? [{ type: 'invoice' as const, id: invoice.id }]
                : []),
            ],
          },
        },
        {
          onSuccess: () => {
            success({
              message: `Your email has successfully been ${
                type === 'send' ? 'sent' : 'queued'
              }`,
            });
            onSuccess();
          },
          onError: (error) =>
            showError({
              message:
                error instanceof Error
                  ? error.message
                  : `There was an error ${
                      type === 'send' ? 'sending' : 'queueing'
                    } the email`,
            }),
        }
      );

  return (
    <div className="sm:w-[800px] lg:w-[1000px] xl:w-[1400px]">
      <ModalHeader title="Send email" />
      <div className="mt-6 mb-6 mx-8">
        <EmailForm
          apiKey={TINYMCE_API_KEY}
          to={customer.email}
          message={process(email.body)}
          subject={process(email.subject)}
          onSubmit={handleSend('send')}
          onQueue={handleSend('queue')}
          isLoading={isLoading}
          additionalOptions={
            <CheckboxGroup
              id="attachment"
              label="Attach invoice PDF to email"
              labelClass="text-gray-600 cursor-pointer"
              checkbox={{
                checked: attachment,
                onClick: () => setAttachment((attached) => !attached),
              }}
            />
          }
        />
      </div>
    </div>
  );
}
