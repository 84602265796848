import {
  BellIcon,
  EventsIcon,
  OptionCard,
  type OptionCardProps,
  PhoneIcon,
} from '@pandler/shared/react-ui';
import { Link } from 'react-router-dom';

const templates: OptionCardProps[] = [
  {
    icon: <BellIcon className="h-full" />,
    title: 'Upcoming Invoice Reminders',
    description:
      'Send scheduled email and SMS notifications to customers when unpaid invoices are approaching their due date.',
  },
  {
    icon: <EventsIcon className="h-full" />,
    title: 'Overdue Invoice Reminders',
    description:
      'Send scheduled email and SMS notifications to customers when invoices remain unpaid past their due date.',
    tag: 'Popular',
  },
  {
    icon: <PhoneIcon className="h-full" />,
    title: 'Debt Collection Escalation',
    description:
      'Escalate invoices to a debt collection workflow when they go unpaid for an extended period.',
  },
];

interface TemplateSelectionProps {
  onSelect?: (template: OptionCardProps) => void;
}

export function TemplateSelection({ onSelect }: TemplateSelectionProps) {
  return (
    <>
      <div className="text-center bg-indigo-700 text-white py-5">
        <h1 className="text-lg antialiased font-semibold">
          Create a new workflow
        </h1>
      </div>

      <div className="mt-8 mb-8 mx-12">
        <div className="flex flex-col divide-y divide-gray-200/75 space-y-10">
          <div>
            <p className="text-sm text-center mb-10">
              Get up and running quickly by choosing from one of our existing
              workflows:
            </p>

            <div className="grid grid-cols-3 gap-6">
              {templates.map((template) => (
                <OptionCard
                  key={template.title}
                  {...template}
                  onClick={() => {
                    if (onSelect) {
                      onSelect(template);
                    }
                  }}
                />
              ))}
            </div>
          </div>

          <div className="flex justify-center pt-6">
            <Link
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
              to="/workflows/empty"
            >
              Or start with an empty workflow<span aria-hidden="true"> →</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
