import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';
import { queryClient } from '@pandler/cloud/shared';

import App from './app/app';
import type { PlatformApiScope } from '@pandler/shared/utils';
import { PlatformApiAudience } from '@pandler/shared/utils';
import { RecoilRoot } from 'recoil';
import {
  ModalProvider,
  SlideOutProvider,
  Toaster,
} from '@pandler/shared/react-ui';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const scopes: PlatformApiScope[] = [
  'read:me',
  'read:organization',
  'write:organization',
  'list:integrations',
  'read:integration',
  'write:integration',
  'read:invoice',
  'write:invoice',
  'read:customer',
  'read:workflow',
  'read:payments',
  'write:payments',
  'write:user',
  'read:templates',
  'write:templates',
  'send:email',
  'read:onboarding',
  'write:onboarding',
  'write:media',
  'read:notifications',
  'write:notifications',
  'read:dashboard',
  'read:activity',
];

root.render(
  <StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        <Auth0Provider
          domain={process.env['NX_AUTH0_DOMAIN'] ?? ''}
          clientId={process.env['NX_AUTH0_CLIENT_ID'] ?? ''}
          redirectUri={window.location.origin}
          audience={PlatformApiAudience}
          scope={scopes.join(' ')}
        >
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <ModalProvider>
                <SlideOutProvider>
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                  <Toaster />
                </SlideOutProvider>
              </ModalProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </Auth0Provider>
      </HelmetProvider>
    </RecoilRoot>
  </StrictMode>
);
