import { useAgreeTermsConditions } from '@pandler/cloud/shared';
import { Button, CheckboxGroup, LoadingIcon } from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { useZorm } from 'react-zorm';
import { z } from 'zod';
import type { StageProps } from '../types';

const TermsFormDefinition = z.object({
  termsAgreement: z.string(),
});

export function Introduction({ onSuccess }: StageProps) {
  const { mutate, isLoading, isSuccess } = useAgreeTermsConditions({
    onSuccess,
  });

  const zo = useZorm('terms-conditions', TermsFormDefinition, {
    onValidSubmit(e) {
      e.preventDefault();
      mutate();
    },
  });

  const isDisabled = isLoading || isSuccess;

  return (
    <section className="pb-32">
      <h2 className="text-3xl font-bold text-gray-900 truncate mb-6">
        Welcome to Pandler
      </h2>

      <p className="text-sm">
        Let's get to know you so we can start automating your invoice
        collections.
      </p>

      <form ref={zo.ref} className="flex flex-col gap-y-8">
        <section className="mt-16">
          <CheckboxGroup
            id={zo.fields.termsAgreement()}
            checkbox={{
              name: zo.fields.termsAgreement(),
              defaultChecked: false,
              disabled: isDisabled,
            }}
            label={
              <span className="font-normal">
                I agree to the{' '}
                <a
                  className="text-indigo-500 hover:text-indigo-600"
                  href="https://pandler.io/terms-condtions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                  className="text-indigo-500 hover:text-indigo-600"
                  href="https://pandler.io/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </span>
            }
          />

          {zo.errors.termsAgreement() && (
            <p className="text-red-600 text-sm mt-2">
              You must agree to the terms and conditions.
            </p>
          )}
        </section>

        <section className="pt-5">
          <Button
            className={classNames(
              'rounded-full justify-center py-3 transition-all duration-500',
              {
                'w-full': !isLoading,
                'w-32': isLoading,
              }
            )}
            type="submit"
            disabled={isDisabled}
          >
            {isLoading ? (
              <LoadingIcon className="w-5 h-5 mx-auto" strokeWidth={4} />
            ) : (
              "Let's start"
            )}
          </Button>
        </section>
      </form>
    </section>
  );
}
