import { XMarkIcon } from '@heroicons/react/24/solid';
import { type ActionProps } from 'react-querybuilder';

export function RemoveRule({ handleOnClick }: ActionProps) {
  return (
    <XMarkIcon
      className="w-4 h-4 cursor-pointer text-gray-800 hover:text-indigo-700"
      onClick={handleOnClick}
      aria-labelledby="Remove"
    />
  );
}
