import {
  useDeleteEmailQueueItem,
  useMarkAllAsRead,
  useMarkAsRead,
  useNotifications,
  useSendEmailQueueItem,
} from '@pandler/cloud/shared';
import { Header, NotificationsList } from '@pandler/shared/notifications-ui';
import { InlineError, LoadingIcon, success } from '@pandler/shared/react-ui';
import { entityToUrl } from './utils/entity-to-url';

export function NotificationsContent() {
  const { mutateAsync: approve } = useSendEmailQueueItem();
  const { mutateAsync: deny } = useDeleteEmailQueueItem();
  const { mutateAsync: markAsRead } = useMarkAsRead();
  const { mutateAsync: markAllAsRead, isLoading: isMarkingAllAsRead } =
    useMarkAllAsRead();
  const { notifications, isLoading, error } = useNotifications({
    status: 'UNREAD',
  });

  if (isLoading || error) {
    return (
      <div className="p-8 flex items-center justify-center">
        {isLoading ? (
          <LoadingIcon className="text-indigo-600 w-5 h-5" strokeWidth={3} />
        ) : (
          <InlineError>Unable to retrieve notifications</InlineError>
        )}
      </div>
    );
  }

  const data = notifications ?? [];

  return (
    <div className="flex flex-col gap-3 divide-y divide-gray-100">
      {data.length > 0 && (
        <Header
          className="px-4"
          markAllAsRead={async () => {
            await markAllAsRead();
          }}
        />
      )}

      <NotificationsList
        notifications={data}
        urlGenerator={entityToUrl}
        markAsRead={async (id: string) => {
          await markAsRead({ id });
        }}
        isLoading={isMarkingAllAsRead}
        onApproveDeny={async ({ entity, type, id }) => {
          if (entity && type === 'APPROVE') {
            await Promise.all([approve({ id: entity.id }), markAsRead({ id })]);
            success({ message: 'Your message has been sent.' });
          } else if (entity && type === 'DENY') {
            await Promise.all([deny({ id: entity.id }), markAsRead({ id })]);
          }
        }}
      />
    </div>
  );
}
