import type { PlatformTemplate } from '../../../types';

export const FinalNoticeEmailTemplate: PlatformTemplate<'final-notice-email'> =
  {
    id: 'final-notice-email',
    type: 'email',
    preview: 'https://i.imgur.com/06ocdjI.png',
    category: ['automated'],
    name: 'Final notice',
    description: 'Final notice of unpaid invoice before escalation.',
    subject:
      'Final Notice: Invoice {{invoice.number}} overdue for {{invoice.overdueInDays}}',
    order: 60,
    content: `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
        body {
          font-family: 'Google Sans', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
          margin: 0;
          padding: 0;
          line-height: 1.65;
          background-color: #f8fafc;
        }

        .root {
          background-color: #f8fafc;
          padding: 25px 0 15px 0;
        }

        .container {
          max-width: 600px;
          margin: 0 auto;
          padding: 25px;
          background-color: #ffffff;
          border-radius: 8px;
        }

        .header {
          display: flex;
          justify-content: flex-start;
          padding: 10px 0 20px 0;
        }

        .logo {
          width: auto;
          max-height: 50px;
        }

        .title {
          font-size: 26px;
          font-weight: bold;
          color: #1e293b;
          margin-bottom: 20px;
        }

        .body {
          font-size: 15px;
          color: #1e293b;
          padding-bottom: 20px;
        }

        .body h2 {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .body ul {
          padding-left: 20px;
          margin-bottom: 20px;
        }

        .body li {
          margin-bottom: 5px;
        }

        .cta-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
        }

        .cta {
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          text-decoration: none;
          padding: 12px 20px;
          margin: 5px;
          border-radius: 5px;
        }

        .cta-primary {
          background-color: #3F3D56;
        }

        .cta-secondary {
          background-color: #4338ca;
        }

        .cta:hover {
          color: #ffffff;
          text-decoration: underline;
        }

        .body a {
          color: #4338ca;
          text-decoration: none;
        }

        .body a:hover {
          color: #4338ca;
          text-decoration: underline;
        }

        .footer {
          font-size: 12px;
          color: #1e293b;
          text-align: center;
          line-height: 1.4;
          padding: 20px 0;
        }

        .company-name {
          font-weight: bold;
        }

        .footer a {
          color: #4338ca;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }

        @media (max-width: 600px) {
          .container {
            padding: 20px;
          }
        }
      </style>
    </head>
    <body>
      <div class="root">
        <div class="container">
          <div class="header">
            <a href="{{company.url}}">
              <img data-variable="company.logo" src="{{company.logo}}" alt="{{company.name}}" class="logo">
            </a>
          </div>
          <h1 class="title">Invoice is due</h1>
          <div class="body">
            <p>Attention {{customer.name}},</p>

            <p>This is the final notice regarding Invoice {{invoice.number}}, which remains unpaid after {{invoice.overdueInDays}} past its due date. We urge you to settle the outstanding amount immediately to avoid further action, such as debt collection or legal proceedings.</p>

            <h2>Payment Options:</h2>

            {{#payment.enabled}}
              <p>Simply click on the link to pay online securely and quickly: <a href="{{payment.url}}">{{payment.url}}</a></p>
            {{/payment.enabled}}

            {{#payment.customInstructions}}
              {{{payment.customInstructions}}}
            {{/payment.customInstructions}}

            <h2>Invoice Details:</h2>
            <ul>
              <li>Due date: {{invoice.dueDate}}</li>
              <li>Invoice number: {{invoice.number}}</li>
              <li>Amount: {{invoice.amount}}</li>
            </ul>

            <p>If you're experiencing any issues or need assistance, please contact us at <a href="mailto:{{company.supportEmail}}">{{company.supportEmail}}</a>{{#company.supportPhone}} or call us at {{company.supportPhone}}{{/company.supportPhone}}.</p>

            <p>{{company.name}}</p>
          </div>

          {{#payment.enabled}}
            <div class="cta-container">
              <a href="{{payment.url}}" class="cta cta-secondary">Make Payment</a>
            </div>
          {{/payment.enabled}}
        </div>
        <div class="footer">
          <span class="company-name">{{company.name}}</span><br>
          {{company.address}}<br>
          {{#company.supportPhone}}{{company.supportPhone}} | {{/company.supportPhone}}
          <a href="{{company.supportEmail}}" class="link">{{company.supportEmail}}</a>
        </div>
      </div>
    </body>
    </html>`,
    dependencies: ['invoice'] as const,
  };
