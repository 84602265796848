import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader, Tabs } from '@pandler/shared/react-ui';
import { tabs } from '../../config';

export function CustomDomainsPage() {
  return (
    <>
      <Helmet>
        <title>Custom Domains</title>
      </Helmet>

      <PageHeader
        title="Custom Domains"
        breadcrumbs={
          <Breadcrumbs
            items={[
              { name: 'Settings', href: '/settings' },
              {
                name: 'Custom Domains',
                href: '/settings/custom-domains',
                current: true,
              },
            ]}
          />
        }
      />

      <Content>
        <Tabs
          contentClassName="lg:max-w-5xl mt-10"
          initialActive="custom-domains"
          tabs={tabs}
        />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(CustomDomainsPage);
