import type { ReactNode} from 'react';
import { useContext } from 'react';
import type { Invoice } from '@pandler/shared/data-types';
import { createCurrencyFormatter, formatDate } from '@pandler/shared/utils';
import { Link } from 'react-router-dom';
import { Row } from './row';
import { useMemo } from 'react';
import { InvoiceStatus } from '../invoice-status';
import {
  Button,
  DefaultClassNames,
  ModalContext,
} from '@pandler/shared/react-ui';
import { SendReminder } from '../send-reminder';
import { DownloadButton } from '../download-button';

interface InvoiceSummaryProps {
  invoice: Invoice;
  onViewMore?: () => void;
}

const Title = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <h4 className="bg-gray-50 dark:bg-gray-600/20 p-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
    {children}
  </h4>
);

export function InvoiceSummary({ invoice, onViewMore }: InvoiceSummaryProps) {
  const { closeModal, showModal } = useContext(ModalContext);
  const formatCurrency = useMemo(
    () =>
      createCurrencyFormatter({
        currency: invoice.currency,
      }),
    [invoice.currency]
  );

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden sm:rounded-lg flex flex-col gap-4">
            <div>
              <Title>#{invoice.number}</Title>
              <table className="min-w-full divide-y divide-gray-200">
                <tbody className="bg-white divide-y divide-gray-200">
                  <Row label="Invoice #">
                    <Link
                      to={`/invoices/view/${invoice.id}`}
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {invoice.number}
                    </Link>
                  </Row>
                  <Row label="Status">
                    <InvoiceStatus status={invoice.status} />
                  </Row>
                  <Row label="Issued on">{formatDate(invoice.issuedDate)}</Row>
                  <Row label="Due date">{formatDate(invoice.dueDate)}</Row>
                </tbody>
              </table>
            </div>

            <div>
              <Title>Total</Title>
              <table className="min-w-full divide-y divide-gray-200">
                <tbody className="bg-white divide-y divide-gray-200">
                  <Row label="Sub-total">
                    {formatCurrency(invoice.subTotal)}
                  </Row>
                  <Row label="GST">{formatCurrency(invoice.taxAmount)}</Row>
                  <Row label="Total">{formatCurrency(invoice.total)}</Row>

                  {['Submitted', 'PartiallyPaid'].includes(invoice.status) && (
                    <>
                      {invoice.amountPaid > 0 && (
                        <Row label="Amount paid">
                          {formatCurrency(invoice.amountPaid)}
                        </Row>
                      )}
                      {invoice.amountDue !== invoice.total &&
                        invoice.amountDue > 0 && (
                          <Row label="Amount due">
                            {formatCurrency(invoice.amountDue)}
                          </Row>
                        )}
                    </>
                  )}
                </tbody>
              </table>
            </div>

            <div className="flex gap-2">
              <Link
                to={`/invoices/view/${invoice.id}`}
                className={`${DefaultClassNames}`}
                onClick={() => {
                  if (onViewMore) {
                    onViewMore();
                  }
                }}
              >
                View invoice
              </Link>

              <DownloadButton
                className="bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-300"
                invoiceId={invoice.id}
                label="Download"
              />

              {['Submitted', 'PartiallyPaid'].includes(invoice.status) && (
                <Button
                  className="bg-white border-gray-200 hover:bg-gray-100 transition-colors duration-300 text-gray-900"
                  onClick={() =>
                    showModal(
                      <SendReminder invoice={invoice} onSuccess={closeModal} />,
                      {
                        className: 'overflow-visible sm:p-0 transition-all',
                        onClose: closeModal,
                      }
                    )
                  }
                >
                  Send reminder
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
