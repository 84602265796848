import classNames from 'classnames';
import { type ReactNode } from 'react';

interface PageButtonProps {
  label: number | string | ReactNode;
  active?: boolean;
  onClick: () => void;
}

export function PageButton({ label, active, onClick }: PageButtonProps) {
  return (
    <button
      className={classNames(
        'border-t-2 pt-4 px-3 inline-flex items-center text-sm font-medium transition-all duration-500',
        {
          'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
            !active,
          'border-indigo-500 text-indigo-600': active,
        }
      )}
      onClick={onClick}
      disabled={active}
    >
      {label}
    </button>
  );
}
