import {
  ModalHeader,
  TemplatesDropdown,
  useCustomer,
  useOrganization,
  useTemplates,
} from '@pandler/cloud/shared';
import type { Invoice } from '@pandler/shared/data-types';
import { InlineError, LoadingIcon } from '@pandler/shared/react-ui';
import { useState } from 'react';
import type { EmailMessage } from '../../types';
import { SendEmailTemplate } from '../send-email-template';

interface SendReminderProps {
  invoice: Invoice;
  title?: string;
  onSuccess: () => void;
}

export function SendReminder({
  title = 'Send reminder',
  invoice,
  onSuccess,
}: SendReminderProps) {
  const [template, setTemplate] = useState<EmailMessage | undefined>();
  const { customer, isLoading, error } = useCustomer(invoice.customerId);
  const templates = useTemplates();
  const organization = useOrganization();

  if (isLoading || templates.isLoading || organization.isLoading) {
    return (
      <div className="px-8 py-6">
        <LoadingIcon className="text-indigo-600 w-5 h-5" strokeWidth={3} />
      </div>
    );
  }

  if (error || !customer || !templates || !organization) {
    return (
      <InlineError>
        {error?.message ??
          'An error occurred retrieving invoice template details'}
      </InlineError>
    );
  }

  if (template) {
    return (
      <SendEmailTemplate
        customer={customer}
        invoice={invoice}
        email={template}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <div className="w-96">
      <ModalHeader title={title} />
      <div className="mt-6 mb-6 mx-8">
        <div className="flex flex-col divide-y divide-gray-200/75 space-y-8">
          <div>
            <p className="text-sm text-gray-500 mb-2.5">Select a template:</p>
            <TemplatesDropdown onSelected={setTemplate} />
          </div>
        </div>
      </div>
    </div>
  );
}
