import { type ComponentProps } from 'react';

export type CheckboxProps = ComponentProps<'input'> & { hasError?: boolean };

export function Checkbox({ className, hasError, ...props }: CheckboxProps) {
  return (
    <input
      type="checkbox"
      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
      {...props}
    />
  );
}
