import { type EditorText } from '@pandler/shared/data-types';
import classNames from 'classnames';
import isHotkey from 'is-hotkey';
import {
  useCallback,
  useMemo,
  type KeyboardEvent,
  useState,
  useEffect,
} from 'react';
import { createEditor, type Descendant } from 'slate';
import { withHistory } from 'slate-history';
import {
  type ReactEditor,
  withReact,
  Slate,
  Editable,
  type RenderLeafProps,
  type RenderElementProps,
} from 'slate-react';
import { Element } from './element';
import { Leaf } from './leaf';
import { Toolbar } from './toolbar';
import { toggleMark } from './utils';

export const DefaultTextEditorValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export interface ToolBarOptions {
  position: 'top' | 'bottom';
}

export type Editor = ReactEditor & { reset: () => void };

export interface RichTextEditorProps {
  placeholder?: string;
  spellCheck?: boolean;
  value?: Descendant[];
  onValueChange?: (value: Descendant[]) => void;
  readOnly?: boolean;
  className?: string;
  toolBar?: ToolBarOptions;
  onInit?: (editor: Editor) => void;
}

const HOTKEYS: Record<string, keyof Omit<EditorText, 'text'>> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

export function RichTextEditor({
  placeholder = '',
  spellCheck = true,
  value = [],
  onValueChange,
  readOnly = false,
  className = '',
  toolBar = { position: 'top' },
  onInit,
}: RichTextEditorProps) {
  const [initialized, setInitialized] = useState(false);
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const editor = useMemo(
    () => withReact(withHistory(createEditor() as ReactEditor)),
    []
  );

  useEffect(() => {
    if (onInit && !initialized && editor) {
      onInit({
        ...editor,
        reset: () => {
          const point = { path: [0, 0], offset: 0 };
          editor.selection = { anchor: point, focus: point };
          editor.history = { redos: [], undos: [] };
          editor.children = value;
        },
      });
      setInitialized(true);
    }
  }, [editor, initialized, onInit, value]);

  function handleOnKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        if (mark) {
          toggleMark(editor, mark);
        }
      }
    }
  }

  return (
    <Slate editor={editor} value={value} onChange={onValueChange}>
      {!readOnly && toolBar.position === 'top' && <Toolbar className="mb-3" />}

      <Editable
        className={classNames(className, {
          'bg-white shadow-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md border px-3.5 py-3 transition-all duration-300':
            !readOnly,
        })}
        placeholder={placeholder}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck={spellCheck}
        onKeyDown={(e) => {
          if (!readOnly) {
            return handleOnKeyDown(e);
          }
        }}
        readOnly={readOnly}
      />

      {!readOnly && toolBar.position === 'bottom' && (
        <Toolbar className="mt-3" />
      )}
    </Slate>
  );
}
