import type { Template } from '@pandler/shared/data-types';
import { isTemplate } from '@pandler/shared/data-types';
import { InlineError, LoadingIcon } from '@pandler/shared/react-ui';
import {
  emailTemplates,
  type PlatformTemplate,
  smsTemplates,
} from '@pandler/shared/templates';
import Select from 'react-select';
import { useTemplates } from '../../hooks';

import styles from './templates-dropdown.module.css';

interface OnSelectedArgs {
  body: string;
  subject: string;
}

interface TemplatesDropdownProps {
  onSelected?: (args: OnSelectedArgs) => void;
}

const byEmail = ({ type }: Template) => type === 'email';
const bySms = ({ type }: Template) => type === 'sms';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toOption = ({ id, name }: Template | PlatformTemplate<any>) => ({
  value: id,
  label: name,
});
const byThoseWithOptions = ({ options }: { options: unknown[] }) =>
  options.length > 0;

const noop = () => {
  // nothing
};

export function TemplatesDropdown({
  onSelected = noop,
}: TemplatesDropdownProps) {
  const { templates, isLoading, error } = useTemplates();

  if (isLoading || !templates) {
    return <LoadingIcon className="text-indigo-700 w-5 h-5" strokeWidth={3} />;
  }

  if (error) {
    return <InlineError>Unable to retrieve templates</InlineError>;
  }

  const allTemplates = [...templates, ...emailTemplates, ...smsTemplates];

  const options = [
    {
      label: 'User emails',
      options: templates.filter(byEmail).map(toOption),
    },
    {
      label: 'User sms messages',
      options: templates.filter(bySms).map(toOption),
    },
    {
      label: 'System emails',
      options: emailTemplates.map(toOption),
    },
    {
      label: 'System sms messages',
      options: smsTemplates.map(toOption),
    },
  ];

  return (
    <div className="container">
      <Select
        options={options.filter(byThoseWithOptions)}
        classNames={{
          input: () => `!flex ${styles['inputContainer']}`,
          option: () => '!text-sm !text-gray-700 !hover:cursor-pointer',
          singleValue: () => '!text-sm !text-gray-600',
        }}
        onChange={(selected) => {
          const { value } = selected as unknown as { value: string };
          const template = allTemplates.find(({ id }) => id === value);
          if (template) {
            if (isTemplate(template)) {
              return onSelected({
                body: template.body,
                subject: template.defaultSubject ?? '',
              });
            }

            return onSelected({
              body: template.content,
              subject: template.subject ?? '',
            });
          }
        }}
      />
    </div>
  );
}
