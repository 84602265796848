import type { Customer} from '@pandler/shared/data-types';
import { addressAsString } from '@pandler/shared/data-types';
import { CustomerDetail } from './customer-detail';
import {
  EnvelopeIcon,
  HomeIcon,
  PhoneIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';

interface CustomerCardProps {
  customer: Customer;
}

export function CustomerCard({ customer }: CustomerCardProps) {
  return (
    <div className="flex flex-col gap-5 items-center justify-center">
      {customer.contactName && (
        <CustomerDetail icon={UserCircleIcon}>
          {customer.contactName}
        </CustomerDetail>
      )}

      {customer.email && (
        <CustomerDetail icon={EnvelopeIcon}>
          <a
            href={`mailto:${customer.email}`}
            className="text-indigo-600 hover:underline hover:text-indigo-700 transition-all"
          >
            {customer.email}
          </a>
        </CustomerDetail>
      )}

      {customer.phone && (
        <CustomerDetail icon={PhoneIcon}>
          <a
            href={`tel:${customer.phone}`}
            className="text-indigo-600 hover:underline hover:text-indigo-700 transition-all"
          >
            {customer.phone}
          </a>
        </CustomerDetail>
      )}

      {customer.address && (
        <CustomerDetail icon={HomeIcon}>
          {addressAsString(customer.address)}
        </CustomerDetail>
      )}
    </div>
  );
}
