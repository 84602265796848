import type { FC, ReactNode } from 'react';
import { type ReactElement } from 'react';
import classNames from 'classnames';
import { Icon } from './icon/icon';
import { twMerge } from 'tailwind-merge';

type RadioListItem = {
  title: string | ReactElement;
  description?: string | ReactElement;
  value: string;
  icon?: ReactNode;
};

export type RadioListGroupProps = {
  items: RadioListItem[];
  optionClassName?: string;
  selected?: string[];
  className?: string;
  itemClassName?: string;
  titleClassName?: string;
  iconClassName?: string;
  descriptionClassName?: string;
  onClick?: (value: RadioListItem) => void;
};

const defaultProps = {
  optionClassName: 'p-5',
};

const RadioListGroup: FC<RadioListGroupProps> = (props) => {
  const {
    items,
    optionClassName,
    selected = [],
    className = '',
    itemClassName = '',
    titleClassName = '',
    descriptionClassName = '',
    iconClassName = '',
    onClick,
  } = props;

  return (
    <ul className={twMerge('lg:grid lg:grid-cols-2 lg:gap-4', className)}>
      {items.map((item, number) => (
        <li
          key={item.value}
          value={item.value}
          className={twMerge(
            classNames(
              `flex border items-center rounded-lg text-base font-medium cursor-pointer border-indigo-800 hover:bg-gray-50 transition-colors ${
                number > 0 ? 'mt-4' : ''
              } lg:mt-0`,
              {
                'border-black-300 text-black-900': !selected.includes(
                  item.value
                ),
                'border-indigo-600 ring-1 ring-indigo-600': selected.includes(
                  item.value
                ),
              }
            ),
            optionClassName,
            itemClassName
          )}
          onClick={() => {
            if (onClick) {
              return onClick(item);
            }
          }}
        >
          <div className="grow flex items-center">
            {item.icon && (
              <div className={twMerge('w-10 h-10 mr-5', iconClassName)}>
                {item.icon}
              </div>
            )}

            <div className="pr-4">
              <div
                className={twMerge(
                  'text-gray-800 text-base font-medium',
                  titleClassName
                )}
              >
                {item.title}
              </div>

              {item.description && (
                <div className={twMerge('text-gray-400', descriptionClassName)}>
                  {item.description}
                </div>
              )}
            </div>
          </div>
          <Icon selected={selected.includes(item.value)} />
        </li>
      ))}
    </ul>
  );
};

RadioListGroup.defaultProps = defaultProps;

export { RadioListGroup };
