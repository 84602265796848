import { type ReactNode, type ReactElement, useState } from 'react';
import { classNames } from '@pandler/shared/utils';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export interface Tab {
  id: string;
  title: string;
  content?: ReactNode;
  url?: string;
}

export interface TabsInputArguments {
  title?: string;
  tabs: Tab[];
  initialActive?: string;
  contentClassName?: string;
  onClick?: (tab: TabsInputArguments['tabs'][number]['id']) => void;
  right?:
    | ReactNode
    | ReactNode
    | ((props: {
        tab?: TabsInputArguments['tabs'][number]['id'];
      }) => ReactElement);
}

export function Tabs(props: TabsInputArguments) {
  const {
    title,
    tabs = [],
    initialActive,
    contentClassName = '',
    onClick,
    right: Right,
  } = props;
  const [activeId, setActiveId] = useState(
    initialActive ?? tabs?.[0]?.id ?? ''
  );
  const navigate = useNavigate();
  const activeTab = tabs.find(({ id }) => id === activeId);

  return (
    <div>
      <nav className="border-b border-gray-200">
        <div className="sm:flex sm:items-baseline">
          {title && (
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
          )}

          <div
            className={classNames('mt-4 sm:mt-0 grow', title ? 'sm:ml-10' : '')}
          >
            <nav className="-mb-px flex space-x-8">
              {tabs.map(({ id, title, url }) => (
                <button
                  key={id}
                  className={classNames(
                    activeId === id
                      ? 'border-indigo-500 text-indigo-600 font-semibold'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={activeId === id ? 'page' : undefined}
                  onClick={() => {
                    if (url) {
                      navigate(url);
                    } else {
                      setActiveId(id);
                    }
                    if (onClick) {
                      onClick(id);
                    }
                  }}
                >
                  {title}
                </button>
              ))}
            </nav>
          </div>

          {Right && typeof Right === 'function' ? (
            <Right tab={activeTab?.id} />
          ) : null}
          {Right && typeof Right === 'object' ? Right : null}
        </div>
      </nav>

      <div className={twMerge('mt-4', contentClassName)}>
        {activeTab && activeTab.content}
      </div>
    </div>
  );
}
