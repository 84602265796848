import { twMerge } from 'tailwind-merge';

export interface LogoIconProps {
  className?: string;
  fill?: string;
}

export function LogoIcon({ className = '' }: LogoIconProps) {
  return (
    <svg
      viewBox="0 0 90 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge('text-indigo-600', className)}
    >
      <path
        d="M72.7031 58.9505C68.25 70.7213 56.849 79.0963 43.4896 79.0963C42.724 79.0963 34.7917 79.0651 34.0365 79.013V64.7057C34.7865 64.8047 42.7188 64.8619 43.4896 64.8619C49.9167 64.8619 55.5104 61.2942 58.3802 56.0338C47.349 52.5026 37.5573 46.2213 29.8125 37.9922C27.7604 40.789 26.5469 44.2369 26.5469 47.9661V109.404L34 93.4401L39.3646 96.5286C41.9167 97.9974 45.0625 97.9974 47.6146 96.5286L82.8594 76.2369C85.4115 74.7682 86.9844 72.0494 86.9844 69.1119V58.7786C84.3698 59.0807 81.7135 59.2474 79.0156 59.2474C76.8854 59.2474 74.7812 59.1432 72.7031 58.9505Z"
        fill="currentColor"
      />
      <path
        d="M4.125 21.3932C1.57292 22.8672 0 25.5807 0 28.5234V69.1119C0 72.0494 1.57292 74.7682 4.125 76.2369L12.276 80.9297V47.9661C12.276 39.5703 15.6094 31.9557 21.026 26.3567C18.849 22.7161 17.0104 18.8567 15.5469 14.8203L4.125 21.3932Z"
        fill="currentColor"
      />
      <path
        d="M82.8594 21.3932L47.6146 1.10153C45.0625 -0.367218 41.9167 -0.367218 39.3646 1.10153L15.5469 14.8203C17.0104 18.8567 18.849 22.7161 21.026 26.3567C26.7031 20.4869 34.6667 16.8359 43.4896 16.8359C60.7292 16.8359 74.7031 30.7734 74.7031 47.9661C74.7031 51.8307 73.9948 55.5338 72.7031 58.9505C74.7813 59.1432 76.8854 59.2474 79.0156 59.2474C81.7135 59.2474 84.3698 59.0807 86.9844 58.7786V28.5234C86.9844 25.5807 85.4115 22.8672 82.8594 21.3932Z"
        fill="currentColor"
      />
      <path
        d="M58.3802 56.0338C59.6875 53.638 60.4323 50.888 60.4323 47.9661C60.4323 38.6328 52.849 31.0651 43.4896 31.0651C37.875 31.0651 32.8958 33.7942 29.8125 37.9921C37.5573 46.2213 47.349 52.5026 58.3802 56.0338Z"
        fill="currentColor"
      />
    </svg>
  );
}
