import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { HomePage, ReturnPage } from './pages';

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="/return" element={<ReturnPage />} />
    </ReactRouterRoutes>
  );
}
