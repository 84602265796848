import type { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface EmptyStateProps {
  title: string | ReactNode | ReactNode[];
  body?: string | ReactNode | ReactNode[];
  className?: string;
  iconClassName?: string;
  icon: (options: { className?: string }) => ReactElement;
  titleClassName?: string;
  bodyClassName?: string;
}

export function EmptyState({
  title,
  body,
  className = '',
  iconClassName = '',
  icon: Icon,
  titleClassName = '',
  bodyClassName = '',
}: EmptyStateProps) {
  return (
    <div
      className={twMerge(
        'flex flex-col space-y-6 justify-center text-center mt-16',
        className
      )}
    >
      <Icon className={twMerge('h-60 mb-4', iconClassName)} />

      <div>
        <h2 className={twMerge('text-xl font-bold mb-1.5', titleClassName)}>
          {title}
        </h2>
        {body && (
          <p
            className={twMerge(
              'text-sm text-gray-500 sm:max-w-sm mx-auto',
              bodyClassName
            )}
          >
            {body}
          </p>
        )}
      </div>
    </div>
  );
}
