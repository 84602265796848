import { Helmet } from 'react-helmet-async';
import { withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { PageWrapper } from '../../components/page-wrapper';
import { LoadingIcon, TimedText } from '@pandler/shared/react-ui';
import { useNavigate } from 'react-router-dom';

export function LinkPage() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Linking</title>
      </Helmet>

      <PageWrapper>
        <div className="flex flex-col space-y-6 justify-center text-center mt-16 mb-24">
          <LoadingIcon
            strokeWidth={2.4}
            className="w-16 h-16 text-indigo-500 mx-auto mb-6 mt-4"
            iconClassName="text-indigo-700 h-6"
          />

          <h2 className="text-xl font-bold mb-1.5">
            <TimedText
              steps={[
                {
                  text: 'Connecting your accounting platform',
                  offset: 0,
                },
                { text: 'Retrieving your company invoices', offset: 4000 },
                {
                  text: 'Configuring your reminder schedule',
                  offset: 12000,
                },
              ]}
              completeOffset={0}
              onComplete={() => {
                navigate('/onboarding/complete');
              }}
            />
          </h2>
          <p className="text-sm text-gray-500 sm:max-w-sm mx-auto">
            Did you know in 2022 our customers got paid on average 40% faster
            when sending reminders through Pandler.
          </p>
        </div>
      </PageWrapper>
    </>
  );
}

export default withAuthenticationRequired(LinkPage);
