import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import type { NavigationItem } from './types';
import { isCurrent } from './utils/is-current';
import { isPopulatedArray } from '@pandler/shared/type-guards';
import { SubMenuItem } from './submenu-item';

export function NavigationLink(props: NavigationItem) {
  const { name, href, icon: Icon, children } = props;
  const location = useLocation();

  if (href && !isPopulatedArray(children)) {
    return (
      <Link
        to={href}
        className={classNames(
          isCurrent(href, location.pathname)
            ? 'bg-indigo-800 text-white'
            : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
          'grow group flex items-center px-2 py-2 text-sm font-medium rounded-md'
        )}
      >
        {Icon && (
          <Icon
            className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
            aria-hidden="true"
          />
        )}
        {name}
      </Link>
    );
  }

  if (isPopulatedArray(children)) {
    return <SubMenuItem {...props} />;
  }

  return null;
}
