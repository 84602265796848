import { useAuth0 } from '@auth0/auth0-react';
import type { Integration } from '@pandler/shared/data-types';
import { error as errorDialog } from '@pandler/shared/react-ui';
import { API_ENDPOINT } from '@pandler/shared/utils';
import Result from 'esresult';
import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useOrganization } from '../organization';
import { GetAccessTokenDefaults } from '../../config';

export default function useAvailableIntegrations() {
  const { organization } = useOrganization();
  const [redirect, setRedirect] = useState<
    { url: string; name: string } | undefined
  >();
  const [connectLoading, setConnectLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, error, data } = useQuery<Integration[], Error>(
    ['availableIntegrations'],
    async () => {
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'list:integrations',
      });
      const $response = await fetch(`${API_ENDPOINT}/integrations/available`, {
        headers: { authorization: `Bearer ${token}` },
      });

      const { data } = await $response.json();
      if (!Array.isArray(data)) {
        throw new Error('Unable to retrieve integrations');
      }

      return data as Integration[];
    },
    { staleTime: Infinity, retry: false }
  );

  const connect = useCallback(
    async (id: string) => {
      setConnectLoading(true);
      const token = await getAccessTokenSilently({
        ...GetAccessTokenDefaults,
        scope: 'write:integration',
      });

      const $ = await Result.try(async () => {
        const response = await fetch(
          `${API_ENDPOINT}/integration/${organization?.id}/connection`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
          }
        );

        if (response.status !== 201) {
          throw new Error('Unable to link data source');
        }

        return (await response.json()) as { data: { url: string } };
      });

      setConnectLoading(false);

      if ($.error) {
        errorDialog({
          message: (
            <>
              Unable to connect data source. If this error persists, please{' '}
              <a
                href="https://pandler.io/support"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:underline hover:text-indigo-700"
              >
                contact support
              </a>
              .
            </>
          ),
        });
        return;
      }

      const item = data?.find(({ id: dataId }) => id === dataId);
      const url = $.value.data.url;
      setRedirect({ url, name: item?.name ?? '' });
      window.location.href = url;
    },
    [data, getAccessTokenSilently, organization?.id]
  );

  return { isLoading, error, data, redirect, connect, connectLoading };
}
