import { queryClient, useOrganizationSync } from '@pandler/cloud/shared';
import { PlatformToast, success } from '@pandler/shared/react-ui';
import { useThrottledCallback } from 'beautiful-react-hooks';
import { useEffect, useState } from 'react';
import { toast as rhToast } from 'react-hot-toast';

export default function useSyncNotification() {
  const { isSyncing } = useOrganizationSync({
    queryOptions: {
      refetchInterval: (data) =>
        data?.sync?.status === 'running' ? 5000 : 30000,
    },
  });
  const [toast, setToast] = useState('');

  const loadingToast = useThrottledCallback(
    () => {
      const toastId = rhToast.custom(
        (t) => {
          return (
            <PlatformToast
              show={t.visible}
              initialVisible
              title="Sync in progress"
              message={
                <span className="text-sm">
                  We're currently retrieving the latest data from your
                  accounting platform(s).{' '}
                  <a
                    href="https://pandler.io/how/sync"
                    className="text-indigo-700 hover:underline hover:text-indigo-800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more.
                  </a>
                </span>
              }
              type="loading"
              dismissible={false}
            />
          );
        },
        { position: 'bottom-right', duration: Infinity }
      );

      setToast(toastId);
    },
    [],
    250,
    { trailing: true }
  );

  useEffect(() => {
    if (isSyncing && !toast) {
      loadingToast();
    } else if (!isSyncing && toast) {
      rhToast.dismiss(toast);
      setToast('');

      queryClient.removeQueries({ queryKey: ['invoices'] });
      success({
        title: 'Sync successful',
        message: (
          <span className="text-sm">
            Your latest accounting data has successfully been retrieved.
          </span>
        ),
        position: 'bottom-right',
      });
    }
  }, [isSyncing, toast, setToast, loadingToast]);
}
