export type CustomFilterProps = TextFilterProps | RangeFilterProps;

export interface TextFilterProps {
  onChange: (value: string | number) => void;
  value: unknown;
  className?: string;
}

const defaultClassName =
  'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md w-full p-2';

export function DateFilter({
  value,
  onChange,
  className = defaultClassName,
}: TextFilterProps) {
  return (
    <input
      type="date"
      value={value as string}
      onChange={(e) => onChange(e.target.value)}
      className={className}
    />
  );
}

export function TextFilter({
  value,
  onChange,
  className = defaultClassName,
}: TextFilterProps) {
  return (
    <input
      type="text"
      value={value as string}
      onChange={(e) => onChange(e.target.value)}
      className={className}
      placeholder=""
    />
  );
}

export interface RangeFilterProps {
  onChange: (value: [string, string]) => void;
  value: unknown;
  className?: string;
}

export function RangeFilter({
  value,
  onChange,
  className = defaultClassName,
}: RangeFilterProps) {
  const val = (value as [string, string]) ?? ['', ''];

  return (
    <>
      <input
        type="number"
        value={val[0] ?? ''}
        onChange={(e) => onChange([e.target.value, val[1]])}
        placeholder={`Min`}
        className={className}
      />
      <input
        type="number"
        value={val[1] ?? ''}
        onChange={(e) => onChange([val[0], e.target.value])}
        placeholder={`Max`}
        className={className}
      />
    </>
  );
}
