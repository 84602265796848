import { BellIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { NotificationsContent } from '../notifications-content';
import { useLocation } from 'react-router-dom';
import { NotificationsStatus } from '../notifications-status';

export function NotificationsBar() {
  const location = useLocation();

  return (
    <Menu as="div" className="ml-3 relative" key={`menu-${location.pathname}`}>
      <div>
        <Menu.Button
          type="button"
          className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 relative"
        >
          <span className="sr-only">View notifications</span>
          <BellIcon className="h-6 w-6" aria-hidden="true" />
          <NotificationsStatus />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none px-2 py-1"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <NotificationsContent />
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
