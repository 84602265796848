export function serializeToBase64<T>(obj: T): string {
  const serializedObj = JSON.stringify(obj);
  const encodedObj = Buffer.from(serializedObj).toString('base64');
  return encodedObj;
}

export function deserializeFromBase64<T>(encodedObj: string): T {
  const serializedObj = Buffer.from(encodedObj, 'base64').toString('utf-8');
  const deserializedObj = JSON.parse(serializedObj);
  return deserializedObj as T;
}
