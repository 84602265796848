export function toTitleCase(input: string) {
  return input.replace(
    /\w\S*/g,
    (word) =>
      word.charAt(0).toLocaleUpperCase() + word.substring(1).toLocaleLowerCase()
  );
}

export const capitalize = toTitleCase;

export function camelCaseToWords(input: string) {
  return input
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(toTitleCase)
    .join(' ');
}
