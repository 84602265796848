import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import type { FC, ReactNode} from 'react';
import { useCallback, useState } from 'react';
import { Button } from '../button';
import { Input } from '../form/input';
import { LoadingIcon } from '../loading-icon';

export type DeleteConfirmationProps = {
  confirmationText?: string;
  onDelete?: () => Promise<void>;
  onCancel: () => void;
  body?: string | ReactNode;
  title?: string;
  confirmButtonLabel?: string;
  placeholder?: string;
  type?: 'error' | 'success' | 'info';
};

const icon = ({
  type,
}: Pick<DeleteConfirmationProps, 'type'>): typeof ExclamationTriangleIcon => {
  switch (type) {
    case 'info':
      return QuestionMarkCircleIcon;
    case 'success':
      return CheckCircleIcon;
    case 'error':
    default:
      return ExclamationTriangleIcon;
  }
};

const DeleteConfirmation: FC<DeleteConfirmationProps> = (props) => {
  const {
    confirmationText,
    onDelete,
    body,
    title,
    onCancel,
    confirmButtonLabel = 'Delete',
    placeholder,
    type = 'error',
  } = props;
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const isDisabled =
    (confirmationText &&
      value.toLowerCase() !== confirmationText.toLowerCase()) ||
    loading;

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    if (onDelete) {
      await onDelete();
    }
    setLoading(false);
  }, [onDelete]);

  const Icon = icon({ type });

  return (
    <div className="sm:flex sm:items-start py-3 pr-4">
      <div
        className={classNames(
          'mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full sm:mx-0 sm:h-12 sm:w-12',
          {
            'bg-red-100 ': type === 'error',
            'bg-emerald-100 ': type === 'success',
            'bg-indigo-100 ': type === 'info',
          }
        )}
      >
        <Icon
          className={classNames('h-7 w-7', {
            'text-red-600': type === 'error',
            'text-emerald-600': type === 'success',
            'text-indigo-600': type === 'info',
          })}
          aria-hidden="true"
        />
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-5 sm:text-left grow">
        {title && (
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
        )}

        <p className="mb-6 mt-2 text-sm">
          {body ?? (
            <>
              Are you sure you want to delete this item? To confirm, please type{' '}
              <strong>{confirmationText}</strong> below:
            </>
          )}
        </p>

        {confirmationText && (
          <div className="flex flex-col mb-6">
            <Input
              name="name"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={placeholder ?? `Type ${confirmationText} to delete`}
              autoComplete="off"
            />
          </div>
        )}

        <div className="flex justify-start">
          <Button
            className="bg-gray-100 text-gray-800 rounded-md mr-3 hover:bg-gray-200/80 border"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className={classNames(
              `rounded-md text-white transition-all duration-500 text-center flex items-center justify-center ${
                loading ? 'w-12' : 'w-20'
              }`,
              {
                'bg-red-500 disabled:bg-red-200 hover:bg-red-600':
                  type === 'error',
                'bg-emerald-500 disabled:bg-emerald-200 hover:bg-emerald-600':
                  type === 'success',
                'bg-indigo-500 disabled:bg-indigo-200 hover:bg-indigo-600':
                  type === 'info',
              }
            )}
            disabled={isDisabled}
            onClick={handleOnClick}
          >
            {loading ? (
              <LoadingIcon className="w-4 h-4 mx-auto" />
            ) : (
              confirmButtonLabel
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { DeleteConfirmation };
