import { PlusSmallIcon } from '@heroicons/react/24/solid';

export function AddNodeButton() {
  return (
    <div className="w-8 h-8 flex items-center justify-center cursor-pointer group absolute -bottom-4 left-0 right-0 mx-auto z-10">
      <div className="w-4 h-4 rounded-full bg-indigo-600 group-hover:w-5 group-hover:h-5 transition-all duration-200 flex items-center justify-center">
        <PlusSmallIcon className="text-white w-3.5 h-3.5 group-hover:w-4 group-hover:h-4" />
      </div>
    </div>
  );
}
