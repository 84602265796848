import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

export interface CircleStepsProps {
  steps: number;
  current: number;
  className?: string;
  showSteps?: boolean;
  stepPosition?: 'top' | 'bottom';
}

export function CircleSteps({
  steps,
  current,
  className = '',
  showSteps = false,
  stepPosition = 'top',
}: CircleStepsProps) {
  const isCompleted = (step: number) => step < current;
  const isActive = (step: number) => step === current;
  const isFuture = (step: number) => step > current;
  const isLast = (step: number) => step === steps - 1;

  const Counter = ({ className = '' }: { className?: string }) => (
    <section className={twMerge('text-gray-500 text-sm', className)}>
      <span className="font-semibold text-gray-800">Step {current + 1}</span> /{' '}
      {steps}
    </section>
  );

  return (
    <nav
      aria-label="Progress"
      className={twMerge('flex flex-col items-center', className)}
    >
      {showSteps && stepPosition === 'top' && <Counter className="mb-4" />}

      <ol className="flex">
        {new Array(steps).fill('').map((value, step) => (
          <li
            className={classNames('relative', {
              'pr-8 sm:pr-20': !isLast(step),
            })}
            key={`step-${step}`}
          >
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div
                className={classNames('h-0.5 w-full', {
                  'bg-indigo-600': isCompleted(step),
                  'bg-gray-200': isFuture(step) || isActive(step),
                })}
              ></div>
            </div>

            <div
              className={classNames(
                'relative w-8 h-8 flex items-center justify-center rounded-full',
                {
                  'bg-indigo-600 hover:bg-indigo-900': isCompleted(step),
                  'bg-white border-2 border-indigo-600': isActive(step),
                  'group bg-white border-2 border-gray-300': isFuture(step),
                }
              )}
            >
              {isCompleted(step) ? (
                <svg
                  className="w-5 h-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <span
                  className={classNames('h-2.5 w-2.5 rounded-full', {
                    'bg-transparent': isFuture(step),
                    'bg-indigo-600': isActive(step),
                  })}
                  aria-hidden="true"
                ></span>
              )}

              <span className="sr-only">Step {step + 1}</span>
            </div>
          </li>
        ))}
      </ol>

      {showSteps && stepPosition === 'bottom' && <Counter className="mt-4" />}
    </nav>
  );
}
