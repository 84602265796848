import { twMerge } from 'tailwind-merge';

export interface ProgressBarProps {
  barClassName?: string;
  className?: string;
  progress?: number;
}

export function ProgressBar({
  barClassName = '',
  className = '',
  progress = 0,
}: ProgressBarProps) {
  return (
    <div
      className={twMerge(
        'w-full bg-indigo-50/50 rounded-full h-2.5 dark:bg-gray-700',
        className
      )}
    >
      <div
        className={twMerge('bg-indigo-600 h-2.5 rounded-full', barClassName)}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
}
