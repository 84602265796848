import { Button, Card, ModalContext, success } from '@pandler/shared/react-ui';
import type { Templates } from '@pandler/shared/templates';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewTemplateForm } from '../new-template-form';

type Template = Templates[number];

export type TemplateSelectionProps = {
  templates: Template[];
};

export function TemplateSelection({ templates }: TemplateSelectionProps) {
  const { closeModal, showModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const handleOnClick = (id: Template['id']) => () => {
    showModal(
      <NewTemplateForm
        template={id}
        onCancel={closeModal}
        onSuccess={(id) => {
          closeModal();
          success({ message: 'Your template has successfully been created' });
          navigate(`/templates/edit/${id}`);
        }}
      />,
      {
        onClose: closeModal,
        className: 'sm:max-w-md sm:w-full',
      }
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-8 gap-y-12">
      {templates.map(({ id, preview, name, description }) => (
        <Card
          key={id}
          className="border-none"
          contentClassName="p-0 pt-4 h-32 overflow-clip"
          image={
            <div
              className="group h-60 relative overflow-hidden cursor-pointer p-0.5"
              onClick={handleOnClick(id)}
            >
              <img
                src={preview}
                alt={name}
                className="border border-gray-100 rounded-lg p-0.5 absolute inset-0 h-full w-full object-scale-down"
              />
              <div className="absolute h-full w-full bg-black/0 group-hover:bg-black/40 transition-colors duration-300 inset-0 rounded-lg" />
              <button
                type="button"
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 inline-flex items-center px-4 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-indigo-600 opacity-0 group-hover:opacity-100 duration-300 transition-all"
              >
                Select
              </button>
            </div>
          }
          title={name}
          description={description}
          footer={
            <Button
              className="grow justify-center bg-indigo-600 hover:bg-indigo-700"
              onClick={handleOnClick(id)}
            >
              Select
            </Button>
          }
          footerClassName="flex mt-4"
        />
      ))}
    </div>
  );
}
