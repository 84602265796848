import { ModalHeader, useEmailQueueItem } from '@pandler/cloud/shared';
import { InlineError, LoadingIcon } from '@pandler/shared/react-ui';

import { EditQueueItemForm } from '../edit-queue-item-form';

interface EditQueueItemProps {
  id: string;
  onClose?: () => void;
  noModal?: boolean;
  goBack?: {
    label?: string;
    onClick: () => void;
  };
}

const noop = () => {
  // nothing
};

export function EditQueueItem({
  id,
  onClose = noop,
  noModal = false,
  goBack,
}: EditQueueItemProps) {
  const { message, isLoading, error } = useEmailQueueItem(id);

  if (isLoading) {
    return (
      <LoadingIcon
        className="text-indigo-600 w-5 h-5 mx-auto"
        strokeWidth={2.5}
      />
    );
  }

  if (error || !message) {
    return (
      <InlineError>
        {error?.message ?? 'Unable to retrieve message'}
      </InlineError>
    );
  }

  const content = (
    <div className="flex flex-col gap-2">
      {goBack ? (
        <div>
          <button
            className="text-indigo-600 underline text-sm shrink hover:text-indigo-700 transition-colors grow-0"
            onClick={goBack.onClick}
          >
            {goBack.label ?? 'Go back'}
          </button>
        </div>
      ) : null}
      <EditQueueItemForm
        className={noModal ? 'p-0' : ''}
        message={message}
        onClose={onClose}
      />
    </div>
  );

  if (noModal) {
    return content;
  }

  return (
    <div className="sm:w-[800px] lg:w-[1000px] xl:w-[1400px]">
      <ModalHeader title="Edit message" />
      <div className="mt-6 mb-6 mx-8">{content}</div>
    </div>
  );
}
