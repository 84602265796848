import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useCallback } from 'react';

interface Step {
  id: string;
  name: string;
  status: 'complete' | 'current' | 'upcoming';
}

export interface HorizontalStepsProps {
  className?: string;
  stepSizeClassName?: string;
  steps: Step[];
  onClick?: (step: Step) => void;
}

export function HorizontalSteps({
  onClick,
  steps,
  className = '',
  stepSizeClassName = 'w-8 h-8 text-xs',
}: HorizontalStepsProps) {
  const handleOnClick = useCallback(
    (step: Step) => () => {
      if (onClick) {
        return onClick(step);
      }
    },
    [onClick]
  );

  return (
    <nav aria-label="Progress" className={className}>
      <ol className="md:flex">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className="relative md:flex-1 md:flex items-center"
          >
            {step.status === 'complete' && (
              <button
                onClick={handleOnClick(step)}
                className="group flex items-center"
              >
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span
                    className={`${stepSizeClassName} flex-shrink-0 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800 transition-all duration-300`}
                  >
                    <CheckIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </button>
            )}

            {step.status === 'current' && (
              <button
                onClick={handleOnClick(step)}
                className="px-6 py-4 flex items-center text-sm font-medium"
                aria-current="step"
              >
                <span
                  className={`${stepSizeClassName} flex-shrink-0 flex items-center justify-center border-2 border-indigo-600 rounded-full`}
                >
                  <span className="text-indigo-600">{stepIdx + 1}</span>
                </span>
                <span className="ml-4 text-sm font-semibold text-indigo-600">
                  {step.name}
                </span>
              </button>
            )}

            {step.status === 'upcoming' && (
              <button className="group flex items-center">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span
                    className={`${stepSizeClassName} flex-shrink-0 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400 transition-all duration-300`}
                  >
                    <span className="text-gray-500 group-hover:text-gray-900">
                      {stepIdx + 1}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900 transition-all duration-300">
                    {step.name}
                  </span>
                </span>
              </button>
            )}

            {stepIdx !== steps.length - 1 && (
              <div className="grow flex justify-center">
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
