import { XMarkIcon } from '@heroicons/react/24/solid';
import { SendEmailAction } from '@pandler/shared/data-types';
import {
  Button,
  CheckboxGroup,
  DefaultTextEditorValue,
  FieldGroupFieldError,
  FormGroup,
  Input,
  RichTextEditor,
} from '@pandler/shared/react-ui';
import { useState } from 'react';
import { useZorm } from 'react-zorm';
import type { Descendant } from 'slate';

export interface SendEmailFormProps {
  data: SendEmailAction;
}

const FormDefinition = SendEmailAction.omit({ type: true });

export function SendEmailForm({ data }: SendEmailFormProps) {
  const [display, setDisplay] = useState<{ bcc: boolean; cc: boolean }>({
    bcc: false,
    cc: false,
  });
  const [contents, setContents] = useState<Descendant[]>(
    DefaultTextEditorValue
  );
  const zo = useZorm('settings', FormDefinition, {
    onValidSubmit(e) {
      e.preventDefault();
      console.log('Form ok!\n' + JSON.stringify(e.data, null, 2));
    },
  });

  return (
    <div>
      <form ref={zo.ref}>
        <FormGroup
          id="to"
          label="Recipient(s)"
          description={
            <p>
              <span className="font-semibold">Note:</span> use tokens to
              dynamically set this value.
            </p>
          }
          input={
            <Input
              type="text"
              name={zo.fields.to()}
              defaultValue={data.to}
              placeholder="email@example.com"
              hasError={zo.errors.to(true)}
            />
          }
          error={zo.errors.to(FieldGroupFieldError)}
        />

        {(!display.bcc || !display.cc) && (
          <div className="-mt-3.5 flex justify-end text-sm cursor-pointer space-x-1.5">
            {!display.cc && (
              <span
                className="text-indigo-600 hover:underline hover:text-indigo-700"
                onClick={() =>
                  setDisplay((value) => ({ ...value, cc: !value.cc }))
                }
              >
                Cc
              </span>
            )}
            {!display.bcc && (
              <span
                className="text-indigo-600 hover:underline hover:text-indigo-700"
                onClick={() =>
                  setDisplay((value) => ({ ...value, bcc: !value.bcc }))
                }
              >
                Bcc
              </span>
            )}
          </div>
        )}

        {display.cc && (
          <FormGroup
            id="cc"
            label="Cc"
            input={
              <div className="relative">
                <Input
                  type="text"
                  name={zo.fields.cc()}
                  defaultValue={data.cc}
                  hasError={zo.errors.cc(true)}
                />
                <XMarkIcon
                  className="w-4 h-4 absolute right-4 top-0 bottom-0 my-auto z-10 text-gray-700 hover:text-gray-900 cursor-pointer transition-colors duration-300"
                  onClick={() =>
                    setDisplay((value) => ({ ...value, cc: false }))
                  }
                />
              </div>
            }
            error={zo.errors.cc(FieldGroupFieldError)}
          />
        )}

        {display.bcc && (
          <FormGroup
            id="bcc"
            label="Bcc"
            input={
              <div className="relative">
                <Input
                  type="text"
                  name={zo.fields.bcc()}
                  defaultValue={data.bcc}
                  hasError={zo.errors.bcc(true)}
                />
                <XMarkIcon
                  className="w-4 h-4 absolute right-4 top-0 bottom-0 my-auto z-10 text-gray-700 hover:text-gray-900 cursor-pointer transition-colors duration-300"
                  onClick={() =>
                    setDisplay((value) => ({ ...value, bcc: false }))
                  }
                />
              </div>
            }
            error={zo.errors.bcc(FieldGroupFieldError)}
          />
        )}

        <FormGroup
          id="subject"
          label="Subject"
          input={
            <Input
              type="text"
              name={zo.fields.subject()}
              defaultValue={data.subject}
              placeholder="e.g. Your invoice is 7 days overdue"
              hasError={zo.errors.subject(true)}
            />
          }
          error={zo.errors.to(FieldGroupFieldError)}
        />

        <FormGroup
          id="body"
          label="Body"
          input={
            <RichTextEditor
              className="min-h-[120px] max-h-[400px] overflow-y-auto"
              placeholder=""
              value={contents}
              onValueChange={setContents}
              toolBar={{ position: 'bottom' }}
            />
          }
          error={zo.errors.body(FieldGroupFieldError)}
        />

        <FormGroup
          id="attachInvoicePdf"
          className="-mt-9 mb-10"
          input={
            <CheckboxGroup
              id={zo.fields.attachInvoicePdf()}
              checkbox={{
                name: zo.fields.attachInvoicePdf(),
                defaultChecked: data.attachInvoicePdf,
              }}
              label="Attach invoice PDF to email"
            />
          }
          error={zo.errors.attachInvoicePdf(FieldGroupFieldError)}
        />

        <Button type="submit">Save</Button>
      </form>
    </div>
  );
}
