import { Switch } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';

export interface ToggleProps {
  className?: string;
  enabled?: boolean;
  onClick?: (enabled: boolean) => void;
}

export function Toggle({
  className = '',
  enabled = false,
  onClick,
}: ToggleProps) {
  return (
    <Switch
      checked={enabled}
      onChange={(enabled: boolean) => {
        if (onClick) {
          return onClick(enabled);
        }
      }}
      className={twMerge(
        'flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600',
        className
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className="pointer-events-none absolute bg-white w-full h-full rounded-md"
      />
      <span
        aria-hidden="true"
        className={classNames(
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
          { 'bg-indigo-600': enabled, 'bg-gray-200': !enabled }
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
          { 'translate-x-5': enabled, 'translate-x-0': !enabled }
        )}
      />
    </Switch>
  );
}
