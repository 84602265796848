import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import {
  useDeleteEmailQueueItem,
  useDownloadInvoicePdf,
  useSendEmailQueueItem,
} from '@pandler/cloud/shared';
import { DetailedMessageView } from '@pandler/shared/inbox-ui';
import { LoadingIcon, error, success } from '@pandler/shared/react-ui';
import { asArray } from '@pandler/shared/type-guards';
import type { EmailLogItem, EmailQueueItem } from '@pandler/shared/data-types';

export type MessageProps = {
  message: EmailQueueItem | EmailLogItem;
  hideActionBar?: boolean;
  hideChart?: boolean;
  innerClassName?: string;
};

export function Message({
  message,
  hideActionBar = false,
  hideChart = false,
  innerClassName = '',
}: MessageProps) {
  const { download, isLoading } = useDownloadInvoicePdf();
  const { mutateAsync: deleteMessage } = useDeleteEmailQueueItem();
  const { mutateAsync: sendMessage } = useSendEmailQueueItem();

  const handleOnClick = (invoiceId: string) => () => {
    download({ invoiceId });
  };

  const handleOnDelete = async () => {
    try {
      await deleteMessage({ id: message.id });
      success({ message: 'Your message has successfully been deleted' });
    } catch {
      error({ message: 'Unable to delete message' });
    }
  };

  const handleOnSend = async () => {
    try {
      await sendMessage({ id: message.id });
      success({ message: 'Your message has successfully been sent' });
    } catch {
      error({ message: 'Unable to delete message' });
    }
  };

  const [email] = asArray(message.email.content);
  const isSent = 'sentAt' in message;
  const date = isSent ? message.sentAt : message.queuedAt;

  return (
    <DetailedMessageView
      hideChart={hideChart}
      innerClassName={innerClassName}
      onSend={handleOnSend}
      onDelete={handleOnDelete}
      hideActionBar={hideActionBar}
      message={{
        status: isSent ? 'sent' : 'pending',
        subject: message.subject,
        date: new Date(date),
        name: message.recipient,
        message: email?.value ?? '',
        attachments: message.email.attachments.map((attachment) => ({
          onClick: handleOnClick(attachment.id),
          fileName: 'Invoice.pdf',
          type: 'pdf',
          size: isLoading ? (
            <LoadingIcon className="w-4 h-4 text-indigo-600" strokeWidth={2} />
          ) : (
            <div className="flex">
              Download
              <ArrowDownTrayIcon className="w-4 h-4 ml-1" />
            </div>
          ),
        })),
      }}
    />
  );
}
