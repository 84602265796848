import type { Tab as TabUi } from '@pandler/shared/react-ui';
import {
  GeneralTab,
  SubscriptionTab,
  IntegrationsTab,
  UsersTab,
  TemplatesTab,
} from './tabs';

export type Tab =
  | 'general'
  | 'subscription'
  | 'users'
  | 'custom-domains'
  | 'integrations'
  | 'templates';

type SettingsTab = TabUi & { id: Tab };

export const tabs: SettingsTab[] = [
  {
    id: 'general',
    title: 'General',
    content: <GeneralTab />,
    url: '/settings',
  },
  {
    id: 'templates',
    title: 'Templates',
    content: <TemplatesTab />,
    url: '/settings/templates',
  },
  {
    id: 'subscription',
    title: 'Subscription',
    content: <SubscriptionTab />,
    url: '/settings/subscription',
  },
  {
    id: 'users',
    title: 'Users',
    content: <UsersTab />,
    url: '/settings/users',
  },
  {
    id: 'integrations',
    title: 'Integrations',
    content: <IntegrationsTab />,
    url: '/settings/integrations',
  },
];
