import { useContext } from 'react';
import type { NodeProps} from 'react-flow-renderer';
import { Handle, Position } from 'react-flow-renderer';
import { WorkflowContext } from '../../../context/workflow-context';
import type { Action } from '../../../types';
import { AddNodeButton } from '../../add-node-button';

export function ActionNode({ data, isConnectable }: NodeProps<Action>) {
  const { type } = data;
  const { getAction } = useContext(WorkflowContext);
  const action = getAction(type);
  if (!action) {
    console.warn(`Action ${type} not found.`);
    return null;
  }

  return (
    <div className="px-4 pt-3 pb-3.5 bg-white shadow rounded w-80 relative cursor-pointer hover:bg-slate-50 transition-colors duration-300">
      <div className="absolute left-0 top-0 w-1 h-full bg-indigo-500 rounded rounded-r-none" />
      <Handle
        type="target"
        position={Position.Top}
        className="invisible"
        isConnectable={isConnectable}
      />
      <div className="flex items-center">
        {action.icon && (
          <div className="w-8 h-8 bg-indigo-200/80 rounded-full flex items-center justify-center">
            {action.icon}
          </div>
        )}
        <div className="grow text-gray-700 ml-3">
          <div className="text-xs text-gray-500 font-semibold">
            {action.title}
          </div>
          <div className="text-sm">{data.title}</div>
        </div>
      </div>

      <AddNodeButton />

      <Handle
        type="source"
        position={Position.Bottom}
        className="invisible"
        isConnectable={isConnectable}
      />
    </div>
  );
}
