import type { Template } from '@pandler/shared/data-types';
import { Link } from 'react-router-dom';

export interface SummaryProps {
  template: Template;
}

export function Summary({ template }: SummaryProps) {
  const { name, id, updatedAt } = template;

  return (
    <div>
      <Link
        to={`/templates/edit/${id}`}
        className="text-indigo-500 hover:text-indigo-600 transition-colors"
      >
        {name}
      </Link>

      {updatedAt && (
        <p className="text-sm">
          <span className="font-medium">Last edited</span> on{' '}
          {new Date(updatedAt).toLocaleString()}
        </p>
      )}
    </div>
  );
}
