import { As } from '@pandler/shared/react-ui';
import { twMerge } from 'tailwind-merge';

export interface HeaderProps {
  className?: string;
  as?: keyof JSX.IntrinsicElements;
  markAllLabel?: string;
  title?: string;
  markAllAsRead?: () => Promise<void>;
}

export function Header({
  className = '',
  as = 'div',
  markAllLabel = 'Mark all as read',
  title = 'Notifications',
  markAllAsRead,
}: HeaderProps) {
  return (
    <As
      as={as}
      className={twMerge('mt-4 flex flex-row items-center', className)}
    >
      <h3 className="text-lg font-medium grow">{title}</h3>

      <button
        className="text-indigo-500 hover:underline text-sm"
        onClick={() => {
          if (markAllAsRead) {
            markAllAsRead();
          }
        }}
      >
        {markAllLabel}
      </button>
    </As>
  );
}
