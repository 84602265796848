import { type Filter, FilterBar } from '@pandler/cloud/shared';
import { useState } from 'react';
import { Logs } from '../logs/logs';

export function LogsFilterableContainer() {
  const [filter, setFilter] = useState<Filter | undefined>();

  return (
    <>
      <div className="mb-4 p-8 pb-0">
        <h1 className="text-2xl antialiased font-bold text-slate-800 mb-1.5">
          Message logs
        </h1>
        <h2 className="text-slate-600 text-base mb-6">
          Invoice reminders sent by e-mail and SMS.
        </h2>
        <FilterBar onChange={setFilter} label="Search messages..." />
      </div>

      <Logs filter={filter} />
    </>
  );
}
