import { type Payment } from '@pandler/shared/data-types';
import { TableSize } from '@pandler/shared/react-ui';
import { createCurrencyFormatter } from '@pandler/shared/utils';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { PaymentMethod } from '../payment-method';
import { PaymentStatus } from '../payment-status';

const columnHelper = createColumnHelper<Payment>();

export const columns = [
  columnHelper.accessor('invoiceNumber', {
    header: 'Invoice #',
    size: TableSize['w-2/12'],
    cell: ({ getValue, row }) => (
      <Link
        to={`/invoices/view/${row.original?.invoiceId}`}
        className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
      >
        {getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor('amount', {
    header: 'Amount',
    size: TableSize['w-2/12'],
    cell: ({ getValue, row }) =>
      createCurrencyFormatter({
        currency: row.original?.currency ?? 'AUD',
      })(getValue() / 100),
  }),
  columnHelper.accessor('payment', {
    id: 'method',
    header: 'Method',
    size: TableSize['w-1/12'],
    cell: ({ getValue }) => {
      const { brand } = getValue();
      return <PaymentMethod type={brand} className="h-6" />;
    },
  }),
  columnHelper.accessor('billing', {
    id: 'email',
    header: 'Email',
    size: TableSize['w-2/12'],
    cell: ({ getValue }) => getValue().email,
  }),
  columnHelper.accessor('billing', {
    id: 'name',
    header: 'Name',
    size: TableSize['w-2/12'],
    cell: ({ getValue }) => getValue().name,
  }),
  columnHelper.accessor('status', {
    size: TableSize['w-1/12'],
    cell: ({ getValue }) => <PaymentStatus status={getValue()} />,
  }),
  columnHelper.accessor('createdAt', {
    header: 'Date',
    cell: ({ getValue }) => new Date(getValue() * 1000).toLocaleString(),
  }),
];
