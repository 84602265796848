import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Breadcrumbs, PageHeader, Tabs } from '@pandler/shared/react-ui';
import { tabs } from '../../config';

export function TemplatesPage() {
  return (
    <>
      <Helmet>
        <title>Templates</title>
      </Helmet>

      <PageHeader
        title="Templates"
        breadcrumbs={
          <Breadcrumbs
            items={[
              { name: 'Settings', href: '/settings/emails' },
              {
                name: 'Templates',
                href: '/settings/templates',
                current: true,
              },
            ]}
          />
        }
      />

      <Content>
        <Tabs contentClassName="mt-10" initialActive="templates" tabs={tabs} />
      </Content>
    </>
  );
}

export default withAuthenticationRequired(TemplatesPage);
