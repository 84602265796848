import classNames from 'classnames';
import { LoadingIcon } from '../loading-icon';
import type { Table} from '@tanstack/react-table';
import { type Cell, type Row, flexRender } from '@tanstack/react-table';
import {
  type RangeFilterProps,
  TableFilter,
  TablePagination,
  getColumnClassNameFromSize,
  type TextFilterProps,
} from '../table';
import type { AnyZodObject } from 'zod';

export type CustomFilters = Record<
  string,
  | ((props: RangeFilterProps) => JSX.Element)
  | ((props: TextFilterProps) => JSX.Element)
>;

export interface EntityTableProps<T = unknown> {
  hidePagination?: boolean;
  narrow?: boolean;
  isPreviousData?: boolean;
  instance: Table<T>;
  onRowClick?: (options: { row: Row<T>; cell: Cell<T, unknown> }) => void;
  customFilters?: CustomFilters;
  model?: AnyZodObject;
}

export function EntityTable<T>({
  hidePagination,
  narrow,
  isPreviousData,
  instance,
  onRowClick,
  customFilters,
  model,
}: EntityTableProps<T>) {
  return (
    <>
      <div
        className={classNames('relative', {
          'shadow-sm border-b border-gray-200 sm:rounded-lg text-sm': !narrow,
        })}
      >
        <div
          className={classNames(
            'absolute left-0 top-0 z-10 bg-gray-600/30 flex items-center justify-center',
            {
              'w-full h-full': isPreviousData,
              'w-0 h-0': !isPreviousData,
            }
          )}
        >
          <LoadingIcon
            className="w-8 h-8 text-indigo-600"
            hideLogo
            strokeWidth={3}
          />
        </div>

        <table
          className={classNames('min-w-full', {
            'divide-y divide-slate-200': !narrow,
          })}
        >
          <thead
            className={classNames({
              'bg-slate-50': !narrow,
              'bg-gray-50 dark:bg-gray-600/20': narrow,
            })}
          >
            {instance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={classNames(
                      `${getColumnClassNameFromSize(header.getSize())}`,
                      {
                        'px-6 py-5 text-left text-black-900 font-semibold capitalize tracking-wider':
                          !narrow,
                        'p-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400':
                          narrow,
                      }
                    )}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {!narrow &&
                        customFilters &&
                        model &&
                        header.column.getCanFilter() ? (
                          <TableFilter
                            as={customFilters[header.column.id]}
                            className="mt-1"
                            column={header.column}
                            model={model}
                          />
                        ) : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {instance.getRowModel().rows.length === 0 && (
              <tr>
                <td colSpan={100} className="px-6 py-5 whitespace-nowrap">
                  No results found
                </td>
              </tr>
            )}

            {instance.getRowModel().rows.map((row, rowIndex) => (
              <tr
                key={row.id}
                className={classNames('cursor-pointer group', {
                  'bg-white border-b border-dashed dark:bg-gray-800 dark:border-gray-700':
                    narrow,
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={
                      narrow
                        ? 'p-3 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 group-hover:bg-gray-50 transition-colors duration-300'
                        : `px-6 py-5 whitespace-nowrap transition-all duration-200 ${
                            rowIndex % 2 === 0
                              ? 'bg-white group-hover:bg-slate-100/75'
                              : 'bg-slate-50 group-hover:bg-slate-100/75'
                          }`
                    }
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick({ row, cell });
                      }
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {!hidePagination && (
        <>
          <div className="h-2" />
          <TablePagination instance={instance} />
        </>
      )}
    </>
  );
}
