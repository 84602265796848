import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import {
  CustomDomainsPage,
  HomePage,
  IntegrationsPage,
  IntegrationsReturnPage,
  SubscriptionPage,
  TemplatesPage,
  UsersPage,
} from './pages';

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="/custom-domains" element={<CustomDomainsPage />} />
      <Route path="/integrations" element={<IntegrationsPage />} />
      <Route path="/integrations/return" element={<IntegrationsReturnPage />} />
      <Route path="/subscription" element={<SubscriptionPage />} />
      <Route path="/templates" element={<TemplatesPage />} />
      <Route path="/users" element={<UsersPage />} />
    </ReactRouterRoutes>
  );
}
