import type {
  Address,
  Customer,
  Invoice as TInvoice} from '@pandler/shared/data-types';
import {
  addressAsString
} from '@pandler/shared/data-types';
import { createCurrencyFormatter } from '@pandler/shared/utils';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';
import { Badge } from '../badge';

export interface InvoiceProps {
  className?: string;
  invoice: TInvoice;
  company: {
    address?: Address;
    name: string;
    logo?: string;
  };
  customer?: Customer;
}

export function Invoice({
  invoice,
  company,
  customer,
  className = '',
}: InvoiceProps) {
  const formatCurrency = createCurrencyFormatter({
    currency: invoice.currency,
  });

  return (
    <div className={twMerge('p-4 sm:p-8 rounded shadow bg-white', className)}>
      <div className="p-4 sm:p-8">
        {/* Invoice header */}
        <div className="sm:flex">
          {/* Invoice number */}
          <h2 className="text-3xl font-bold text-gray-900 grow mb-4 sm:mb-0">
            Invoice #{invoice.number}
          </h2>
          {/* Company details */}
          <div className="flex flex-col sm:items-end sm:max-w-xs sm:text-right mb-4 sm:mb-0">
            {company.logo && (
              <img
                src={company.logo}
                className="w-10 h-10 rounded-full mb-3"
                alt={company.name}
              />
            )}
            <div className="text-lg font-bold text-gray-900 mb-0.5">
              {company.name}
            </div>
            {company.address && (
              <div className="text-sm mb-2">
                {addressAsString(company.address)}
              </div>
            )}
          </div>
        </div>

        {(invoice.status === 'Paid' || invoice.status === 'Draft') && (
          <div className="inline-flex flex-col gap-2">
            <div className="text-gray-800 font-medium text-sm">Status</div>
            <Badge
              className="uppercase"
              type={`${invoice.status === 'Paid' ? 'success' : 'dark'}`}
              rounded
            >
              {invoice.status}
            </Badge>
          </div>
        )}

        <div className="flex mt-8">
          {/* Bill to address */}
          {customer && customer.address && (
            <section className="sm:max-w-sm mb-8">
              <div className="uppercase text-gray-900 mb-2 font-bold">
                Bill to
              </div>
              <div className="text-gray-500 italic">
                <div className="font-medium">{customer.name}</div>
                {customer.address.streetName.map((line) => (
                  <div key={line}>{line}</div>
                ))}
                {customer.address.city},{' '}
                {customer.address.state ? `${customer.address.state}, ` : ''}
                {customer.address.postCode}, {customer.address.country}
              </div>
            </section>
          )}

          <div className="grow text-right">
            <div className="text-sm text-gray-500 mb-2">
              <div className="text-gray-800 font-medium">Issue date</div>
              <div>{dayjs(invoice.issuedDate).format('MMMM D, YYYY')}</div>
            </div>

            <div className="text-sm text-gray-500">
              <div className="text-gray-800 font-medium">Due date</div>
              <div>{dayjs(invoice.dueDate).format('MMMM D, YYYY')}</div>
            </div>
          </div>
        </div>

        {/* Line items table */}
        <section className="mb-10 border-b border-gray-200 overflow-x-auto">
          <table className="w-full text-left rounded-md overflow-hidden mb-4 max-w-full">
            <thead className="bg-slate-50 text-xs font-medium uppercase text-gray-900">
              <tr>
                <th className="w-5/12 p-4">Item</th>
                <th className="p-4 text-right">Unit price</th>
                <th className="p-4 text-right">Qty</th>
                <th className="p-4 text-right">Tax amount</th>
                <th className="p-4 text-right">Sub total</th>
                <th className="p-4 text-right">Total</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {invoice.lineItems.map((lineItem, index) => (
                <tr key={`line-item--${index}`}>
                  <td className="p-4">
                    <div className="text-gray-900 font-semibold">
                      {lineItem.description}
                    </div>
                  </td>
                  <td className="p-4 text-right text-gray-600">
                    {formatCurrency(lineItem.unitAmount)}
                  </td>
                  <td className="p-4 text-right font-semibold">
                    {lineItem.quantity}
                  </td>
                  <td className="p-4 text-right">
                    {formatCurrency(lineItem.taxAmount)}
                  </td>
                  <td className="p-4 text-right">
                    {formatCurrency(lineItem.subTotal)}
                  </td>
                  <td className="p-4 text-right font-semibold">
                    {formatCurrency(lineItem.totalAmount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        {/* Total summary */}
        <div className="md:max-w-xs ml-auto text-sm">
          {/* Sub total */}
          <div className="flex mb-3">
            <div className="text-gray-600 grow uppercase text-sm">
              Sub total
            </div>
            <div className="font-medium">
              {formatCurrency(invoice.subTotal)}
            </div>
          </div>
          {/* Tax rate */}
          <div className="flex mb-3">
            <div className="text-gray-600 grow uppercase text-sm">
              Tax amount
            </div>
            <div className="font-medium">
              {formatCurrency(invoice.taxAmount)}
            </div>
          </div>
          {/* Total */}
          <div className="flex">
            <div className="grow uppercase font-semibold">Total</div>
            <div className="font-semibold">{formatCurrency(invoice.total)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
