import { Fragment, useMemo, type ReactNode } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { classNames } from '@pandler/shared/utils';
import { BiUser } from 'react-icons/bi';
import { MdLogout } from 'react-icons/md';
import { Item } from './item';
import { UserSummary } from './user-summary';
import { useCurrentUser } from '@pandler/cloud/shared';

interface MenuItem {
  icon?: ReactNode;
  url?: string;
  onClick?: () => void;
  label: string;
}

type MenuGroup = MenuItem[];
type MenuGroups = MenuGroup[];

interface UserBarProps {
  className?: string;
}

export function UserBar(props: UserBarProps) {
  const { logout } = useAuth0();
  const { user } = useCurrentUser();

  const userNavigation: MenuGroups = useMemo(
    () => [
      [
        {
          label: 'Your profile',
          icon: <BiUser />,
          url: '/user/profile',
        },
      ],
      [
        {
          label: 'Log out',
          icon: <MdLogout />,
          onClick: () => {
            logout({ returnTo: window.location.origin });
          },
        },
      ],
    ],
    [logout]
  );

  if (!user) {
    return null;
  }

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="sr-only">Open user menu</span>
          {user.avatar && (
            <img className="h-8 w-8 rounded-full" src={user.avatar} alt="" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none px-2 py-1"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <UserSummary user={user} />

          {userNavigation.map((group, index) => (
            <div className="py-1 flex" role="none" key={`group-${index}`}>
              {group.map(({ label, url, onClick, icon }) => (
                <Menu.Item key={label}>
                  {({ active }) => (
                    <>
                      {url && (
                        <Link
                          to={url}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex-grow'
                          )}
                          role="menuitem"
                          tabIndex={-1}
                        >
                          <Item label={label} icon={icon} />
                        </Link>
                      )}
                      {!url && (
                        <button
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex-grow'
                          )}
                          onClick={onClick}
                          role="menuitem"
                          tabIndex={-1}
                        >
                          <Item label={label} icon={icon} />
                        </button>
                      )}
                    </>
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
