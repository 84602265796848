import { Helmet } from 'react-helmet-async';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { LoadingPaymentsIcon } from './svgs/icon';
import { LoadingIcon, CheckIcon } from '@pandler/shared/react-ui';
import usePaymentSettings from '../../hooks/use-payment-settings';
import { useEffect, useState } from 'react';
import { type PaymentSettings } from '@pandler/shared/data-types';
import { useNavigate } from 'react-router-dom';

const REDIRECT_DELAY = 2000;

export function ReturnPage() {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(2000);
  const navigate = useNavigate();

  const { data } = usePaymentSettings({
    refetchInterval,
    onSuccess: ({ status }: PaymentSettings) => {
      if (status === 'VERIFIED') {
        setRefetchInterval(false);
      }
    },
  });

  useEffect(() => {
    let timeout: number;
    if (data?.status === 'VERIFIED') {
      timeout = setTimeout(() => {
        navigate('/payments');
      }, REDIRECT_DELAY) as unknown as number;
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [data?.status, navigate]);

  const isLoading = !data || data.status === 'UNVERIFIED';

  return (
    <>
      <Helmet>
        <title>Verifying payments</title>
      </Helmet>

      <Content>
        <div className="flex flex-col space-y-6 justify-center text-center mt-16">
          <LoadingPaymentsIcon className="h-48 mb-4" />

          {isLoading && (
            <>
              <LoadingIcon
                strokeWidth={1.9}
                className="w-12 h-12 text-indigo-600 mx-auto mb-6 mt-4"
                iconClassName="text-indigo-700 h-6"
              />

              <h2 className="text-xl font-bold mb-1.5">
                Verification in progress
              </h2>
              <p className="text-sm text-gray-500 sm:max-w-sm mx-auto">
                Your payment settings are currently being verified.
                <br />
                This should only take a moment.
              </p>
            </>
          )}

          {data?.status === 'VERIFIED' && (
            <>
              <CheckIcon className="w-12 h-12 text-emerald-500 mx-auto mb-6 mt-4" />

              <h2 className="text-xl font-bold mb-1.5">Success!</h2>
              <p className="text-sm text-gray-500 sm:max-w-sm mx-auto">
                Your details have been successfully verified.
                <br />
                You will now be redirected.
              </p>
            </>
          )}
        </div>
      </Content>
    </>
  );
}

export default withAuthenticationRequired(ReturnPage);
