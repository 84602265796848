import type { Payment } from '@pandler/shared/data-types';
import { createCurrencyFormatter } from '@pandler/shared/utils';
import { Link } from 'react-router-dom';
import { PaymentMethod } from '../payment-method';
import { PaymentStatus } from '../payment-status';
import { Row } from './row';

interface PaymentSummaryProps {
  payment: Payment;
}

export function PaymentSummary({ payment }: PaymentSummaryProps) {
  const formatCurrency = createCurrencyFormatter({
    currency: payment.currency,
  });

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                <Row label="Amount">{formatCurrency(payment.amount / 100)}</Row>
                <Row label="Invoice">
                  <Link
                    to={`/invoices/view/${payment.invoiceId}`}
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {payment.invoiceNumber}
                  </Link>
                </Row>
                <Row
                  label="Method"
                  className="flex flex-row gap-3 items-center"
                >
                  <PaymentMethod type={payment.payment.brand} />
                  <span className="capitalize">{payment.payment.brand}</span>
                </Row>
                <Row label="Date & time">
                  {new Date(payment.createdAt * 1000).toLocaleString()}
                </Row>
                <Row label="Status">
                  <PaymentStatus status={payment.status} />
                </Row>
                <Row label="Name">{payment.billing.name}</Row>
                <Row label="Email">{payment.billing.email}</Row>
                <Row label="Receipt">
                  <a
                    href={payment.receipt}
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    View
                  </a>
                </Row>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
