import classNames from 'classnames';

type IconProps = {
  selected?: boolean;
};

export function Icon(props: IconProps) {
  const { selected } = props;

  return (
    <div
      className={classNames(
        'flex items-center justify-center border rounded-full w-6 h-6 bg-white',
        {
          'border-indigo-600 ring-1 ring-indigo-600': selected,
          'border-black-300': !selected,
        }
      )}
    >
      {selected && <div className="bg-indigo-600 w-3.5 h-3.5 rounded-full" />}
    </div>
  );
}
