import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useLocation, Link } from 'react-router-dom';

import type { NavigationItem } from './types';

export function SubMenuItem(props: NavigationItem) {
  const { name, href = '', icon: Icon, children = [] } = props;
  const location = useLocation();

  return (
    <Disclosure
      as="div"
      className="space-y-1 flex flex-col"
      defaultOpen={location.pathname.startsWith(href)}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="hover:bg-indigo-600 hover:bg-opacity-75 grow group flex items-center px-2 py-2 text-sm font-medium rounded-md text-white">
            {Icon && (
              <Icon
                className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                aria-hidden="true"
              />
            )}
            <span className="grow text-left">{name}</span>
            <svg
              className={classNames(
                open ? 'rotate-90' : '',
                'ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150 text-indigo-200'
              )}
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
            </svg>
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="flex space-y-1 flex-col">
              {children.map((subItem) => (
                <Link
                  key={subItem.name}
                  to={subItem.href ?? ''}
                  className={classNames(
                    subItem.href === location.pathname
                      ? 'bg-indigo-800'
                      : 'hover:bg-indigo-600 hover:bg-opacity-75',
                    'grow group flex items-center px-2 py-2 text-sm rounded-md pl-11 pr-2 text-white opacity-90'
                  )}
                >
                  {subItem.name}
                </Link>
              ))}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
