import { type ReactNode } from 'react';
import { GoogleIcon } from '../icons';

export interface Auth0ScreenProps {
  logo?: ReactNode;
  title?: string;
  subTitle?: ReactNode;
  rightSection?: ReactNode;
}

export function Auth0Screen({
  logo,
  title = 'Welcome',
  subTitle = (
    <>
      Sign in or sign up to continue to{' '}
      <span className="font-semibold text-indigo-600">Pandler</span>.
    </>
  ),
  rightSection,
}: Auth0ScreenProps) {
  return (
    <div className="min-h-full flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            {logo}
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              {title}
            </h2>
            {subTitle && <p className="mt-2 text-gray-600">{subTitle}</p>}
          </div>

          <div className="mt-8">
            <div>
              <div>
                <div className="mt-1 grid grid-cols-1 gap-3">
                  <div>
                    <button
                      id="btn-google"
                      className="w-full inline-flex gap-x-2 justify-center py-3 px-4 border border-gray-200 rounded-md bg-white text-sm font-semibold text-gray-700 hover:bg-gray-50"
                    >
                      <GoogleIcon className="w-5 h-5" />
                      <span>Sign in with Google</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-6 relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>
            </div>

            <div id="error-message" className="alert alert-danger"></div>

            <div className="mt-6">
              <form method="post" className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm text-gray-700 font-semibold"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-3 border border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-semibold text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none block w-full px-3 py-3 border border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-700 focus:ring-indigo-600 border-gray-200 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900 cursor-pointer"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <a
                      // @todo forgot link
                      href="https://pandler.io/"
                      className="font-medium text-indigo-700 hover:text-indigo-600"
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div className="captcha-container form-group"></div>

                <div className="space-y-2">
                  <div>
                    <button
                      type="submit"
                      id="btn-login"
                      className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                    >
                      Sign in
                    </button>
                  </div>
                  <div>
                    <button
                      type="submit"
                      id="btn-signup"
                      className="w-full inline-flex gap-x-2 justify-center py-3 px-4 border border-gray-200 rounded-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1 overflow-hidden">
        {rightSection}
      </div>
    </div>
  );
}
