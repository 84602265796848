import { type ReactNode } from 'react';

type VerticalListItem = {
  id: string;
  title: string;
  description?: string | ReactNode;
  rightPanel?: string | ReactNode;
};

export interface VerticalListInputArguments {
  items: VerticalListItem[];
}

export function VerticalList({ items }: VerticalListInputArguments) {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {items.map((item) => (
          <li key={item.id}>
            <div className="hover:bg-gray-50 cursor-pointer">
              <div className="px-4 py-4 sm:px-6">
                <div className="sm:flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-indigo-600 truncate">
                      {item.title}
                    </p>
                    {item.description}
                  </div>
                  {item.rightPanel && <div>{item.rightPanel}</div>}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
