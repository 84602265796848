import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export const OnboardingNotification = z.enum(['visible', 'dismissed']);
export type OnboardingNotification = z.infer<typeof OnboardingNotification>;

export const OnboardingRecord = z.object({
  organizationId: z.string().uuid().default(uuidv4),
  quickStartNotification: OnboardingNotification.default('visible'),
  membersNotification: OnboardingNotification.default('visible'),
  welcomeNotification: OnboardingNotification.default('visible'),
});

export type OnboardingRecord = z.infer<typeof OnboardingRecord>;

export const OnboardingResponse = OnboardingRecord.extend({
  hasVerifiedPayments: z.boolean(),
  hasCustomizedBranding: z.boolean(),
  hasCustomTemplates: z.boolean(),
});

export type OnboardingResponse = z.infer<typeof OnboardingResponse>;
