import { Fragment, type ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

export interface SlideOutProps {
  children: ReactNode;
  open?: boolean;
  onClose?: () => void;
  innerClassName?: string;
  containerClassName?: string;
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  childrenContainerClassName?: string;
}

export function SlideOut({
  open,
  onClose,
  children,
  title,
  subTitle,
  innerClassName = 'w-screen max-w-md',
  containerClassName = 'fixed inset-y-0 pl-16 max-w-full right-0 flex',
  childrenContainerClassName = '',
}: SlideOutProps) {
  const handleOnClose = () => {
    if (onClose) {
      return onClose();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-10"
        onClose={handleOnClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity cursor-pointer" />
          </Transition.Child>

          <div className={containerClassName}>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={innerClassName}>
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    {(title || subTitle) && (
                      <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                        {title && (
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              {title}
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                type="button"
                                className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={handleOnClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        )}

                        {subTitle && (
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              {subTitle}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="flex-1 flex flex-col justify-between">
                      <div
                        className={twMerge(
                          'py-4 px-4 divide-y divide-gray-200 sm:px-6',
                          childrenContainerClassName
                        )}
                      >
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
