import { Routes, Route } from 'react-router-dom';
import { MainLayout, NotFoundPage, AuthWrapper } from '@pandler/cloud/skeleton';
import {
  Routes as DashboardRoutes,
  HomePage as DashboardHomePage,
} from '@pandler/cloud/dashboard';
import { Routes as InvoicesRoutes } from '@pandler/cloud/invoices';
import { Routes as PaymentsRoutes } from '@pandler/cloud/payments';
import { Routes as WorkflowsRoutes } from '@pandler/cloud/workflows';
import { Routes as OnboardingRoutes } from '@pandler/cloud/onboarding';
import { Routes as SettingsRoutes } from '@pandler/cloud/settings';
import { Routes as TemplateRoutes } from '@pandler/cloud/templates';
import { Routes as MessagesRoutes } from '@pandler/cloud/messages';
import { Routes as UserRoutes } from '@pandler/cloud/user';

export function App() {
  return (
    <AuthWrapper>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<DashboardHomePage />} />
          <Route path="/dashboard/*" element={<DashboardRoutes />} />
          <Route path="/invoices/*" element={<InvoicesRoutes />} />
          <Route path="/payments/*" element={<PaymentsRoutes />} />
          <Route path="/workflows/*" element={<WorkflowsRoutes />} />
          <Route path="/onboarding/*" element={<OnboardingRoutes />} />
          <Route path="/settings/*" element={<SettingsRoutes />} />
          <Route path="/templates/*" element={<TemplateRoutes />} />
          <Route path="/notifications/*" element={<MessagesRoutes />} />
          <Route path="/user/*" element={<UserRoutes />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthWrapper>
  );
}

export default App;
