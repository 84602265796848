import { type Invoice } from '@pandler/shared/data-types';
import { ActivityFeed, Alert, LoadingIcon } from '@pandler/shared/react-ui';
import classNames from 'classnames';
import { CommentsForm } from '../comments-form';

interface CommentsProps {
  invoice: Invoice;
  showLoading?: boolean;
}

export function Comments({ invoice, showLoading = false }: CommentsProps) {
  return (
    <>
      <div className="relative">
        <div
          className={classNames(
            'absolute left-0 top-0 z-10 bg-gray-600/30 flex items-center justify-center rounded-lg',
            {
              'w-full h-full': showLoading,
              'w-0 h-0': !showLoading,
            }
          )}
        >
          <LoadingIcon className="w-6 h-6 text-indigo-600" />
        </div>

        {invoice.activity.length > 0 && (
          <ActivityFeed items={invoice.activity} />
        )}

        {invoice.activity.length === 0 && (
          <Alert
            type="warning"
            description="There is no recorded activity on this invoice."
          />
        )}
      </div>

      <CommentsForm invoice={invoice} />
    </>
  );
}
