import {
  Button,
  ContentSlider,
  error,
  LoadingIcon,
} from '@pandler/shared/react-ui';
import { useEffect } from 'react';
import useCreatePaymentSettings from '../../hooks/use-create-payment-settings';
import { EmptyStateIcon } from './svgs/icon';

export function EmptyState() {
  const { isLoading, mutate, data } = useCreatePaymentSettings();

  useEffect(() => {
    if (data) {
      if (!data.error) {
        window.location.href = data.value.url;
      } else {
        error({
          message: 'Unable to set up payments. Please try again.',
        });
      }
    }
  }, [data]);

  return (
    <ContentSlider
      mainPane={({ slide }) => (
        <div className="flex flex-col space-y-6 justify-center text-center mt-16">
          <EmptyStateIcon className="h-60 mb-4" />
          <div>
            <h2 className="text-xl font-bold mb-1.5">
              You have not configured payments
            </h2>
            <p className="text-sm text-gray-500 sm:max-w-sm mx-auto">
              Start accepting online payments for your invoices paid directly
              into your bank account.
            </p>
          </div>

          <div>
            <Button onClick={slide}>Get started</Button>
          </div>
        </div>
      )}
      slidePane={({ goBack }) => (
        <div className="flex flex-col space-y-6 justify-center text-center mt-16 items-center">
          <div className="text-center sm:max-w-sm">
            <img
              src="https://stripe.com/img/v3/home/twitter.png"
              alt="Stripe Logo"
              className="w-24 h-24 mx-auto mb-4 rounded-full"
            />
            <h2 className="text-xl font-bold mb-1.5">
              Pandler uses{' '}
              <a
                href="https://stripe.com/en-au"
                target="_blank"
                rel="noreferrer"
                className="text-indigo-600 hover:text-indigo-700 transition-colors duration-500"
              >
                Stripe
              </a>{' '}
              to provide payments
            </h2>
          </div>
          <div className="sm:max-w-md mt-4 text-slate-500 text-sm">
            <p>
              Stripe is a leading global payment platform that allows businesses
              to accept payments online securely and efficiently.
            </p>
          </div>
          <div className="sm:max-w-lg mt-2.5 text-slate-500 text-sm">
            <p>
              Stripe trusted by millions of businesses, from startups to Fortune
              500 companies. By using Stripe, Pandler ensures that your payments
              are secure, fast, and hassle-free.
            </p>
          </div>
          <div className="pt-2 flex justify-center space-x-2">
            <Button disabled={isLoading} onClick={() => mutate()}>
              {isLoading ? (
                <LoadingIcon className="w-3.5 h-3.5 text-white" />
              ) : (
                'Continue'
              )}
            </Button>
            <Button
              className="bg-white border border-slate-200 hover:bg-slate-100 text-gray-700"
              onClick={goBack}
            >
              Go Back
            </Button>
          </div>
        </div>
      )}
    />
  );
}
