import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';
import { type Step, VerticalSteps, Button } from '@pandler/shared/react-ui';
import { type ReactNode } from 'react';

export interface TemplateModalProps {
  title: string;
  description: string;
  steps?: Step[];
  body: ReactNode;
  onBackClick?: () => void;
  onNextClick?: () => void;
  backDisabled?: boolean;
  nextDisabled?: boolean;
  nextButtonText?: string;
  hideBack?: boolean;
  hideNavigation?: boolean;
}

export function TemplateModal({
  title,
  description,
  steps,
  body,
  onBackClick,
  onNextClick,
  backDisabled,
  nextDisabled,
  nextButtonText = 'Next',
  hideBack,
  hideNavigation,
}: TemplateModalProps) {
  return (
    <div className="w-full flex">
      <div
        className="bg-indigo-700 px-10 py-9 w-[45%]"
        style={{
          backgroundImage:
            'url(https://cdn-id.pandler.dev/0bab21fd8e1a0e94.jpeg)', // @todo asset
        }}
      >
        <h2 className="text-2xl font-semibold text-gray-100 mb-3">{title}</h2>
        <p className="text-sm text-gray-200">{description}</p>

        {steps && (
          <VerticalSteps
            className="mt-6 mb-2 cursor-default"
            itemClassName="py-3 pl-6 hover:bg-black/10 rounded-lg transition-colors duration-300"
            itemProgressClassName="left-10 "
            itemPaddingClassName="pb-4"
            completeContainerClassName="text-gray-200"
            currentContainerClassName="text-gray-200"
            pendingContainerClassName="text-gray-200"
            descriptionClassName="opacity-80"
            steps={steps}
          />
        )}
      </div>
      <div className="w-[55%] bg-white flex items-center relative">
        {body}

        {!hideNavigation && (
          <nav className="absolute flex w-full bottom-0 left-0 px-6 pb-5 justify-between">
            {!hideBack ? (
              <button
                className="border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center text-gray-400 hover:bg-gray-50 hover:text-gray-500 transition-all"
                disabled={backDisabled}
                onClick={onBackClick}
              >
                <ArrowLongLeftIcon className="w-4" />
              </button>
            ) : (
              <span />
            )}

            <Button onClick={onNextClick} disabled={nextDisabled}>
              {nextButtonText}
            </Button>
          </nav>
        )}
      </div>
    </div>
  );
}
