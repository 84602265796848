import { Helmet } from 'react-helmet-async';
import {
  Breadcrumbs,
  ErrorDialog,
  Modal,
  PageHeader,
  SuccessDialog,
} from '@pandler/shared/react-ui';
import { Content, withAuthenticationRequired } from '@pandler/cloud/skeleton';
import { Link, useSearchParams } from 'react-router-dom';

export function IntegrationsReturnPage() {
  const [params] = useSearchParams();
  const error = params.get('error');
  const code = parseInt(params.get('code') ?? '', 10);
  const isSuccess = [200, 201].includes(code);

  const backButton = (
    <Link
      to="/settings/integrations"
      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      Continue
    </Link>
  );

  return (
    <>
      <Helmet>
        <title>Connection</title>
      </Helmet>

      <PageHeader
        title="Integrations"
        breadcrumbs={
          <Breadcrumbs
            items={[
              { name: 'Settings', href: '/settings' },
              { name: 'Integrations', href: '/settings/integrations' },
              {
                name: 'Connect',
                href: '/settings/integrations/return',
                current: true,
              },
            ]}
          />
        }
      />

      <Content>
        <Modal open>
          {isSuccess && (
            <SuccessDialog
              title="Connection successful"
              description="Your data source has successfully been connected. Please allow up to one hour to start syncing."
              footer={backButton}
            />
          )}

          {!isSuccess && (
            <ErrorDialog
              title="Connection error"
              description={
                <div className="text-sm text-gray-500">
                  <p>
                    There was an error when we were trying to connect your data
                    source.
                  </p>
                  {error && (
                    <p className="mt-2">
                      <span className="font-semibold text-gray-700">
                        Error:{' '}
                      </span>
                      {error}
                    </p>
                  )}
                </div>
              }
              footer={backButton}
            />
          )}
        </Modal>
      </Content>
    </>
  );
}

export default withAuthenticationRequired(IntegrationsReturnPage);
