import { type Notification as TNotification } from '@pandler/shared/data-types';
import type { EmptyStateProps } from '../empty-state';
import { EmptyState } from '../empty-state';
import type { NotificationProps } from '../notification/notification';
import { Notification } from '../notification/notification';
import { twMerge } from 'tailwind-merge';
import { LoadingIcon } from '@pandler/shared/react-ui';
import { type ApproveDenyProps } from '../approve-deny';

export interface NotificationsListProps {
  notifications: TNotification[];
  className?: string;
  emptyStateTitle?: EmptyStateProps['title'];
  emptyStateDescription?: EmptyStateProps['description'];
  urlGenerator?: NotificationProps['urlGenerator'];
  markAsRead?: NotificationProps['markAsRead'];
  isLoading?: boolean;
  onApproveDeny?: ApproveDenyProps['onClick'];
}

export function NotificationsList({
  notifications,
  className = '',
  emptyStateTitle,
  emptyStateDescription,
  urlGenerator,
  markAsRead,
  isLoading,
  onApproveDeny,
}: NotificationsListProps) {
  if (notifications.length === 0) {
    return (
      <EmptyState title={emptyStateTitle} description={emptyStateDescription} />
    );
  }

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute inset-0 w-full h-full bg-gray-800/25 flex items-center justify-center z-10 rounded-md">
          <LoadingIcon className="w-5 h-5 text-indigo-600" />
        </div>
      )}
      <ul
        className={twMerge(
          'flex flex-col p-3 divide-y divide-slate-200 max-h-[600px] overflow-y-scroll',
          className
        )}
      >
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            as="li"
            className="py-3 first:pt-0 last:pb-0"
            urlGenerator={urlGenerator}
            markAsRead={markAsRead}
            onApproveDeny={onApproveDeny}
            {...notification}
          />
        ))}
      </ul>
    </div>
  );
}
